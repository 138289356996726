import {trpc} from '@/api/trpcClient';
import {Tab} from '@headlessui/react';
import {zodResolver} from '@hookform/resolvers/zod';
import {t} from '@lingui/macro';
import {SanitizeHTML, Typography, cn, useNotification} from '@zentact/ui-tailwind';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';

const termsAndConditionsHtmlFormSchema = z.object({
  termsHtml: z.string(),
});

export type TermsAndConditionsHtmlFormData = z.infer<typeof termsAndConditionsHtmlFormSchema>;

export const TermsAndConditionsForm = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {data: terms} = trpc.tenant.getTermsAndConditions.useQuery();
  const {register, handleSubmit, watch, reset} = useForm<TermsAndConditionsHtmlFormData>({
    resolver: zodResolver(termsAndConditionsHtmlFormSchema),
    defaultValues: {
      termsHtml: terms?.termsHtml || '',
    },
  });
  const html = watch('termsHtml');

  useEffect(() => {
    reset({
      termsHtml: terms?.termsHtml || '',
    });
  }, [terms]);

  const updateTenantRegistrationConfiguration =
    trpc.tenant.updateRegistrationConfiguration.useMutation({
      onSuccess: () => {
        showSuccessNotification(t`Terms and conditions html has been updated`);
      },
      onError: () => {
        showErrorNotification(
          t`Failed to update terms and conditions html`,
          t`Something went wrong. Please try again later.`
        );
      },
    });

  const submitForm = async (values: TermsAndConditionsHtmlFormData) => {
    updateTenantRegistrationConfiguration.mutate(values);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Tab.Group>
        <Tab.List className="flex items-center group">
          <Tab
            className={({selected}) =>
              cn(
                'ml-2 rounded-md border border-transparent bg-white px-3 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none',
                selected ? 'bg-gray-100 text-gray-900 hover:bg-gray-200' : ''
              )
            }
          >
            {t`Html`}
          </Tab>
          <Tab
            className={({selected}) =>
              cn(
                'ml-2 rounded-md border border-transparent bg-white px-3 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:outline-none',
                selected ? 'bg-gray-100 text-gray-900 hover:bg-gray-200' : ''
              )
            }
          >
            {t`Preview`}
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel className="-m-0.5 rounded-lg p-0.5 h-[469px]">
            <div className="h-full">
              <textarea
                {...register('termsHtml')}
                placeholder="Enter the HTML here..."
                className="block w-full h-full resize-none rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm/6"
                defaultValue={''}
              />
            </div>
          </Tab.Panel>
          <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
            {html.length > 0 ? (
              <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                  <Typography as="h3" variant="header-lg">
                    {t`Terms And Conditions`}
                  </Typography>
                </div>
                <div className="px-4 py-6 text-sm text-gray-800 sm:p-8 max-h-[400px] overflow-y-auto">
                  <SanitizeHTML html={html} />
                </div>
              </div>
            ) : (
              <div className="px-3 pt-2 pb-12 mx-px mt-px text-sm text-gray-800">
                Preview content will render here.
              </div>
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <div className="flex justify-end mt-2">
        <button
          type="submit"
          className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary-600 hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        >
          {t`Save`}
        </button>
      </div>
    </form>
  );
};
