import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  CurrencyCode,
  FEE_MIN_AND_MAX_RESTRICTIONS,
  LocaleCode,
  formatAmount,
  fromMinorUnits,
  toMinorUnits,
} from '@zentact/common';
import {
  Button,
  InputRadio,
  InputSelect,
  InputText,
  InputToggle,
  Label,
  Section,
  Typography,
  ValidationError,
  cn,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useMemo} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import z from 'zod';
import {OverridePricingSection} from './override-pricing-section';

const tenantIntervalFeesLimit = 5;

const getMonthSelectOptions = () => [
  {
    id: '0',
    label: t`January`,
  },
  {
    id: '1',
    label: t`February`,
  },
  {
    id: '2',
    label: t`March`,
  },
  {
    id: '3',
    label: t`April`,
  },
  {
    id: '4',
    label: t`May`,
  },
  {
    id: '5',
    label: t`June`,
  },
  {
    id: '6',
    label: t`July`,
  },
  {
    id: '7',
    label: t`August`,
  },
  {
    id: '8',
    label: t`September`,
  },
  {
    id: '9',
    label: t`October`,
  },
  {
    id: '10',
    label: t`November`,
  },
  {
    id: '11',
    label: t`December`,
  },
];

const getSplitPercentageSchema = (tenantCurrency: CurrencyCode) =>
  z.coerce
    .number({
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      invalid_type_error: t`Percentage must be a number`,
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      required_error: t`Fee is required`,
    })
    .min(
      fromMinorUnits(FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage, tenantCurrency),
      t`Percentage must be greater than or equal to ${fromMinorUnits(
        FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage,
        tenantCurrency
      )}%`
    )
    .max(
      fromMinorUnits(FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage, tenantCurrency),
      t`Percentage must not be larger than ${fromMinorUnits(
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage,
        tenantCurrency
      )}%`
    );

const getSplitFixedSchema = (
  locale: LocaleCode,
  min: number,
  max: number,
  tenantCurrency: CurrencyCode
) =>
  z.coerce
    .number({
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      invalid_type_error: t`Fee must be a number`,
      // biome-ignore lint/style/useNamingConvention: ZOD conventions
      required_error: t`Fee is required`,
    })
    .multipleOf(0.01, t`Fee must be a multiple of 0.01`)
    .min(
      fromMinorUnits(min, tenantCurrency),
      t`Fee must be greater than or equal to ${formatAmount(min, locale, tenantCurrency)}`
    )
    .max(
      fromMinorUnits(max, tenantCurrency),
      t`Fee must be less than or equal to ${formatAmount(max, locale, tenantCurrency)}`
    );

export const getSplitConfigurationGroupFormSchema = (
  locale: LocaleCode,
  tenantCurrency: CurrencyCode,
  isACHPaymentMethodEnabled?: boolean
) =>
  z
    .object({
      name: z
        .string()
        .min(1, t`Name is required`)
        .max(100, t`Name must be less than 100 characters`),
      isDefault: z.boolean(),
      isActive: z.boolean(),

      cardPricingModel: z.enum(['FLAT', 'COST_PLUS']),
      cardSplitPercentageFlat: getSplitPercentageSchema(tenantCurrency).optional(),
      cardSplitPercentageCostPlus: z.coerce
        .number({
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          invalid_type_error: t`Percentage must be a number`,
          // biome-ignore lint/style/useNamingConvention: ZOD conventions
          required_error: t`Fee is required`,
        })
        .int(t`Percentage must be an integer`)
        .min(
          FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage,
          t`Percentage must be greater than or equal to ${fromMinorUnits(
            FEE_MIN_AND_MAX_RESTRICTIONS.minPercentage,
            tenantCurrency
          )}%`
        )
        .max(
          FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage,
          t`Percentage must not be larger than ${fromMinorUnits(
            FEE_MIN_AND_MAX_RESTRICTIONS.maxPercentage,
            tenantCurrency
          )}%`
        )
        .optional(),
      cardSplitFixed: getSplitFixedSchema(
        locale,
        FEE_MIN_AND_MAX_RESTRICTIONS.minFixedFee,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxFixedFee,
        tenantCurrency
      ).optional(),

      interacSplitFixed: getSplitFixedSchema(
        locale,
        FEE_MIN_AND_MAX_RESTRICTIONS.minInteracSplitFixed,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxInteracSplitFixed,
        tenantCurrency
      ).optional(),
      amexSplitFixed: getSplitFixedSchema(
        locale,
        FEE_MIN_AND_MAX_RESTRICTIONS.minAmexSplitFixed,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxAmexSplitFixed,
        tenantCurrency
      ).optional(),
      amexSplitPercentage: getSplitPercentageSchema(tenantCurrency).optional(),

      achFixedOrPercentage: z.enum(['FIXED', 'PERCENTAGE']),
      achSplitPercentage: getSplitPercentageSchema(tenantCurrency).optional(),
      achSplitFixed: getSplitFixedSchema(
        locale,
        FEE_MIN_AND_MAX_RESTRICTIONS.minAchSplitFixed,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxAchSplitFixed,
        tenantCurrency
      ).optional(),

      paybybankSplitPercentage: getSplitPercentageSchema(tenantCurrency).optional(),
      paybybankSplitFixed: getSplitFixedSchema(
        locale,
        FEE_MIN_AND_MAX_RESTRICTIONS.minFixedFee,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxFixedFee,
        tenantCurrency
      ).optional(),

      chargebackFeesModel: z.enum(['TENANT_PAY', 'MERCHANT_PAY']),
      additionalChargebackFee: getSplitFixedSchema(
        locale,
        0,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPerChargebackFee,
        tenantCurrency
      ).optional(),
      refundSplitModel: z.enum(['MERCHANT_PAY', 'REVERSE_PAYMENT_SPLIT']),
      refundFeesModel: z.enum(['TENANT_PAY', 'MERCHANT_PAY']),
      additionalRefundFee: getSplitFixedSchema(
        locale,
        0,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPerRefundFee,
        tenantCurrency
      ).optional(),
      perDeviceFee: getSplitFixedSchema(
        locale,
        0,
        FEE_MIN_AND_MAX_RESTRICTIONS.maxPerDeviceFee,
        tenantCurrency
      ).optional(),
      tenantIntervalFees: z.array(
        z.object({
          name: z.string().optional(),
          amount: getSplitFixedSchema(
            locale,
            0,
            FEE_MIN_AND_MAX_RESTRICTIONS.maxTenantIntervalFee,
            tenantCurrency
          ).optional(),
          period: z.enum(['MONTHLY', 'ANNUAL']),
          annualFeeCollectMonth: z.string().optional(),
        })
      ),
      showOverrideFeeInput: z.boolean(),
      overrideFeeSectionContent: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (
        !data.cardSplitPercentageFlat &&
        !data.cardSplitPercentageCostPlus &&
        !data.cardSplitFixed
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['cardSplitPercentageFlat'],
          message: t`Card split percentage or fixed fee is required`,
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['cardSplitPercentageCostPlus'],
          message: t`Card split percentage or fixed fee is required`,
        });
      }
      if (isACHPaymentMethodEnabled && !data.achSplitPercentage && !data.achSplitFixed) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['achSplitPercentage'],
          message: t`Ach split percentage or fixed fee is required`,
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['achSplitFixed'],
          message: t`Ach split percentage or fixed fee is required`,
        });
      }
      data.tenantIntervalFees.forEach((fee, index) => {
        if (fee.name || fee.amount) {
          if (!fee.name) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['tenantIntervalFees', index, 'name'],
              message: t`Monthly fee must have a name`,
            });
          }

          if (!fee.amount) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['tenantIntervalFees', index, 'amount'],
              message: t`Monthly fee has a name but missing the amount`,
            });
          }
        }
      });
    });

export type SplitConfigurationGroupFormData = z.infer<
  ReturnType<typeof getSplitConfigurationGroupFormSchema>
>;

type Props = {
  form: UseFormReturn<SplitConfigurationGroupFormData>;
  readOnly?: boolean;
  storesCount?: number;
};

export const FeeGroupForm = ({form, readOnly, storesCount}: Props) => {
  const {tenant, paymentMethods, currency} = useStore();
  const {locale} = useLocale();
  const {
    register,
    control,
    formState: {errors},
    watch,
    setValue,
    resetField,
    getValues,
  } = form;
  const isActive = watch('isActive');
  const showOverrideFeeInput = watch('showOverrideFeeInput');
  const cardPricingModel = watch('cardPricingModel');
  const cardSplitPercentage =
    (cardPricingModel === 'FLAT'
      ? watch('cardSplitPercentageFlat')
      : (watch('cardSplitPercentageCostPlus') || 0) / 100) || 0;
  const cardSplitFixed = watch('cardSplitFixed') || 0;
  const achFixedOrPercentage = watch('achFixedOrPercentage');
  const tenantIntervalFees = watch('tenantIntervalFees') ?? [];
  const html = watch('overrideFeeSectionContent');

  const isAmexPaymentMethodEnabled = useMemo(
    () => paymentMethods?.includes('AMEX'),
    [paymentMethods]
  );
  const isInteracPaymentMethodEnabled = useMemo(
    () => paymentMethods?.includes('INTERAC'),
    [paymentMethods]
  );
  const isACHPaymentMethodEnabled = useMemo(
    () => paymentMethods?.includes('ACH'),
    [paymentMethods]
  );
  const isPayByBankPaymentMethodEnabled = useMemo(
    () => paymentMethods?.includes('PAYBYBANK'),
    [paymentMethods]
  );
  const additionalChargebackFee = watch('additionalChargebackFee');
  const additionalRefundFee = watch('additionalRefundFee');
  const perDeviceFee = watch('perDeviceFee');

  const readOnlyClassName = readOnly ? 'bg-gray-100 text-gray-400' : '';

  const cardPricingModelField = register('cardPricingModel');
  const isCostPlusCardPricingModel = cardPricingModel === 'COST_PLUS';

  useEffect(() => {
    resetField('cardSplitPercentageFlat');
    resetField('cardSplitPercentageCostPlus');
  }, [isCostPlusCardPricingModel]);

  useEffect(() => {
    resetField('achSplitFixed');
    resetField('achSplitPercentage');
  }, [achFixedOrPercentage]);

  const onAddNewTenantIntervalFee = useCallback(() => {
    if (tenantIntervalFees.length < tenantIntervalFeesLimit) {
      setValue('tenantIntervalFees', [...tenantIntervalFees, {period: 'MONTHLY'}]);
    }
  }, [tenantIntervalFees, setValue]);

  const onRemoveTenantIntervalFee = useCallback(
    (index: number) => {
      const newTenantIntervalFees = [
        ...tenantIntervalFees.slice(0, index),
        ...tenantIntervalFees.slice(index + 1),
      ];
      setValue('tenantIntervalFees', newTenantIntervalFees);
    },
    [tenantIntervalFees, setValue]
  );

  const currencySymbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  return (
    <div>
      <Section title={<Trans>General</Trans>}>
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <Label text={t`Name`}>
            <InputText
              {...register('name', {required: true})}
              hasError={Boolean(errors.name)}
              readOnly={readOnly}
              disabled={readOnly}
              placeholder={t`Fee Group Name`}
              className={readOnlyClassName}
            />
            <ValidationError isVisible={Boolean(errors.name)}>
              {errors.name?.message}
            </ValidationError>
          </Label>

          <div>
            <InputToggle
              toggleSize="lg"
              {...register('isDefault')}
              readOnly={readOnly}
              disabled={readOnly}
              label={<Trans>Set as Default</Trans>}
            />
            <Typography variant="field-helper" className="mt-2">
              <Trans>
                When onboarding a new merchant, these fees will be selected, but you can pick from
                any active fee sets.
              </Trans>
            </Typography>
          </div>
          <div>
            <InputToggle
              toggleSize="lg"
              {...register('isActive')}
              readOnly={readOnly}
              disabled={readOnly}
              label={<Trans>Active</Trans>}
            />
            <Typography variant="field-helper" className="mt-2">
              {isActive && <Trans>All merchants can be added to this fee group</Trans>}

              {!isActive && !storesCount && (
                <Trans>New stores cannot be assigned to this fee group</Trans>
              )}

              {!isActive && storesCount && (
                <Trans>
                  This fee group still applies to {storesCount} stores, but new stores cannot use
                  this fee group
                </Trans>
              )}
            </Typography>
          </div>
        </div>
      </Section>
      <Section
        title={<Trans>All Payment Methods (unless specified below)</Trans>}
        subTitle={
          <Trans>
            Choose from Cost Plus (to add a specified margin on top of the cost, covering the
            product/service cost and ensuring a set profit) or Flat Percent (to charge a fixed
            percentage on the total sale price, providing a consistent share of the revenue).
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <div className="flex items-center gap-x-16">
            <InputRadio
              {...cardPricingModelField}
              value="COST_PLUS"
              label={<Trans>Cost Plus</Trans>}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <InputRadio
              {...cardPricingModelField}
              value="FLAT"
              label={<Trans>Flat Percentage</Trans>}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </div>

          <div className="sm:col-span-3">
            {isCostPlusCardPricingModel && (
              <Label
                text={t`Basis Points on Top of Cost (Merchant bank fee, Scheme fees, Interchange fee)`}
              >
                <Controller
                  control={control}
                  name="cardSplitPercentageCostPlus"
                  render={({field: {ref, ...rest}}) => (
                    <InputText
                      ref={ref}
                      {...rest}
                      readOnly={readOnly}
                      disabled={readOnly}
                      className={readOnlyClassName}
                      hasError={Boolean(errors.cardSplitPercentageCostPlus)}
                    />
                  )}
                />
                <ValidationError isVisible={Boolean(errors.cardSplitPercentageCostPlus)}>
                  {errors.cardSplitPercentageCostPlus?.message}
                </ValidationError>
              </Label>
            )}

            {cardPricingModel === 'FLAT' && (
              <Label text={t`Percentage of Sale %`}>
                <Controller
                  control={control}
                  name="cardSplitPercentageFlat"
                  render={({field: {ref, value, ...rest}}) => (
                    <InputText
                      ref={ref}
                      {...rest}
                      addonBefore="%"
                      value={readOnly && value ? value.toFixed(2) : value}
                      readOnly={readOnly}
                      disabled={readOnly}
                      className={readOnlyClassName}
                      hasError={Boolean(errors.cardSplitPercentageFlat)}
                    />
                  )}
                />
                <ValidationError isVisible={Boolean(errors.cardSplitPercentageFlat)}>
                  {errors.cardSplitPercentageFlat?.message}
                </ValidationError>
              </Label>
            )}
          </div>
          <div className="sm:col-span-3">
            <Label text={t`Additional Per Transaction Fee`}>
              <InputText
                addonBefore={currencySymbol}
                {...register('cardSplitFixed')}
                value={readOnly && cardSplitFixed ? cardSplitFixed.toFixed(2) : undefined}
                readOnly={readOnly}
                disabled={readOnly}
                className={readOnlyClassName}
                hasError={Boolean(errors.cardSplitFixed)}
              />
              <ValidationError isVisible={Boolean(errors.cardSplitFixed)}>
                {errors.cardSplitFixed?.message}
              </ValidationError>
              <Typography variant="field-helper" as="div" className="mt-2">
                <p className="text-xs leading-5 text-gray-400">
                  <Trans>For example</Trans>
                  {isCostPlusCardPricingModel && (
                    <Trans>
                      , with the 'Cost Plus' model, the merchant covers variable processing fees:
                    </Trans>
                  )}
                </p>
                <ul className="ml-5 text-xs text-gray-400 list-disc">
                  {[5, 100].map((exampleAmount, i) => {
                    const fees =
                      (exampleAmount / 100) * Number(cardSplitPercentage) + Number(cardSplitFixed);
                    const feeAmount = toMinorUnits(fees, currency);
                    const transactionAmount = toMinorUnits(exampleAmount - fees, currency);

                    return (
                      <li key={`example-${i}`}>
                        a {formatAmount(exampleAmount * 100, locale, currency)} purchase will have{' '}
                        {transactionAmount < 0 ? (
                          'fees greater than the transaction amount, so this payment amount would cause an error'
                        ) : (
                          <>
                            a {formatAmount(feeAmount, locale, currency)} fee.{' '}
                            {formatAmount(transactionAmount, locale, currency)}{' '}
                            {isCostPlusCardPricingModel ? t`minus the processing costs` : ''} will
                            go to merchant and {formatAmount(feeAmount, locale, currency)} to{' '}
                            {tenant?.name}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            </Label>
          </div>
        </div>
      </Section>
      {isInteracPaymentMethodEnabled && (
        <Section
          title={t`Interac`}
          subTitle={
            <Trans>
              The Interac pricing model follows the transaction fee pricing model. If you enter a
              transaction fee (up to .25 cents) in the below fixed field, it will be applied to all
              Interac transactions. No other fees will be applied to this transaction.
            </Trans>
          }
        >
          <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
            <Label text={t`Fixed Fee ${currencySymbol}`}>
              <Controller
                control={control}
                name="interacSplitFixed"
                render={({field: {ref, value, ...rest}}) => (
                  <InputText
                    ref={ref}
                    {...rest}
                    addonBefore={currencySymbol}
                    value={readOnly && value ? value.toFixed(2) : value}
                    readOnly={readOnly}
                    disabled={readOnly}
                    className={readOnlyClassName}
                    hasError={Boolean(errors.interacSplitFixed)}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.interacSplitFixed)}>
                {errors.interacSplitFixed?.message}
              </ValidationError>
            </Label>
          </div>
        </Section>
      )}
      {isAmexPaymentMethodEnabled && (
        <Section
          title={t`Amex`}
          subTitle={
            <Trans>
              The Amex cost pricing model follows pricing model of the credit cards. If you choose
              the "Cost Plus" option, an additional fixed fee will be charged to the merchant on top
              of the interchange fees. If you select the "Flat Percentage" option, a single,
              consistent fee will be applied.
            </Trans>
          }
        >
          <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
            <Label text={t`Percentage Fee %`} key="amexSplitPercentage">
              <Controller
                control={control}
                name="amexSplitPercentage"
                render={({field: {ref, value, ...rest}}) => (
                  <InputText
                    ref={ref}
                    {...rest}
                    addonBefore="%"
                    value={readOnly && value ? value.toFixed(2) : value}
                    readOnly={readOnly}
                    disabled={readOnly}
                    className={readOnlyClassName}
                    hasError={Boolean(errors.amexSplitPercentage)}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.amexSplitPercentage)}>
                {errors.amexSplitPercentage?.message}
              </ValidationError>
            </Label>
            <Label text={t`Fixed Fee ${currencySymbol}`}>
              <Controller
                control={control}
                name="amexSplitFixed"
                render={({field: {ref, value, ...rest}}) => (
                  <InputText
                    ref={ref}
                    {...rest}
                    addonBefore={currencySymbol}
                    value={readOnly && value ? value.toFixed(2) : value}
                    readOnly={readOnly}
                    disabled={readOnly}
                    className={readOnlyClassName}
                    hasError={Boolean(errors.amexSplitFixed)}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.amexSplitFixed)}>
                {errors.amexSplitFixed?.message}
              </ValidationError>
            </Label>
          </div>
        </Section>
      )}
      {isACHPaymentMethodEnabled && (
        <Section
          title={<Trans>ACH</Trans>}
          subTitle={
            <Trans>
              Choose from either a fixed fee, a percentage fee, or a combination of both.
            </Trans>
          }
        >
          <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
            <div className="flex items-center gap-x-16">
              <InputRadio
                {...register('achFixedOrPercentage')}
                value="FIXED"
                label={<Trans>Fixed Fee</Trans>}
                readOnly={readOnly}
                disabled={readOnly}
              />
              <InputRadio
                {...register('achFixedOrPercentage')}
                value="PERCENTAGE"
                label={<Trans>Percentage Fee</Trans>}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </div>

            {achFixedOrPercentage === 'PERCENTAGE' ? (
              <Label text={t`Percentage Fee %`} key="achSplitPercentage">
                <Controller
                  control={control}
                  name="achSplitPercentage"
                  render={({field: {ref, value, ...rest}}) => (
                    <InputText
                      ref={ref}
                      {...rest}
                      addonBefore="%"
                      value={readOnly && value ? value.toFixed(2) : value}
                      readOnly={readOnly}
                      disabled={readOnly}
                      className={readOnlyClassName}
                      hasError={Boolean(errors.achSplitPercentage)}
                    />
                  )}
                />
                <ValidationError isVisible={Boolean(errors.achSplitPercentage)}>
                  {errors.achSplitPercentage?.message}
                </ValidationError>
              </Label>
            ) : (
              <Label text={t`Fixed Fee ${currencySymbol}`} key="achSplitFixed">
                <Controller
                  control={control}
                  name="achSplitFixed"
                  render={({field: {ref, value, ...rest}}) => (
                    <InputText
                      ref={ref}
                      {...rest}
                      addonBefore={currencySymbol}
                      value={readOnly && value ? value.toFixed(2) : value}
                      readOnly={readOnly}
                      disabled={readOnly}
                      className={readOnlyClassName}
                      hasError={Boolean(errors.achSplitFixed)}
                    />
                  )}
                />
                <ValidationError isVisible={Boolean(errors.achSplitFixed)}>
                  {errors.achSplitFixed?.message}
                </ValidationError>
              </Label>
            )}
          </div>
        </Section>
      )}
      {isPayByBankPaymentMethodEnabled && (
        <Section
          title={<Trans>Pay By Bank</Trans>}
          subTitle={
            <Trans>
              Choose from either a fixed fee, a percentage fee, or a combination of both.
            </Trans>
          }
        >
          <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
            <Label text={t`Percentage Fee %`} key="paybybankSplitPercentage">
              <Controller
                control={control}
                name="paybybankSplitPercentage"
                render={({field: {ref, value, ...rest}}) => (
                  <InputText
                    ref={ref}
                    {...rest}
                    addonBefore="%"
                    value={readOnly && value ? value.toFixed(2) : value}
                    readOnly={readOnly}
                    disabled={readOnly}
                    className={readOnlyClassName}
                    hasError={Boolean(errors.paybybankSplitPercentage)}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.paybybankSplitPercentage)}>
                {errors.paybybankSplitPercentage?.message}
              </ValidationError>
            </Label>
            <Label text={t`Fixed Fee ${currencySymbol}`} key="paybybankSplitFixed">
              <Controller
                control={control}
                name="paybybankSplitFixed"
                render={({field: {ref, value, ...rest}}) => (
                  <InputText
                    ref={ref}
                    {...rest}
                    addonBefore={currencySymbol}
                    value={readOnly && value ? value.toFixed(2) : value}
                    readOnly={readOnly}
                    disabled={readOnly}
                    className={readOnlyClassName}
                    hasError={Boolean(errors.paybybankSplitFixed)}
                  />
                )}
              />
              <ValidationError isVisible={Boolean(errors.paybybankSplitFixed)}>
                {errors.paybybankSplitFixed?.message}
              </ValidationError>
            </Label>
          </div>
        </Section>
      )}
      <Section
        title={<Trans>Chargeback Fees</Trans>}
        subTitle={
          <Trans>
            <p>
              Adyen charges you for chargebacks based on your agreement. You can either cover these
              fees yourself or pass them on to the merchant. Additionally, you can impose an extra
              fee per transaction on the merchant. For instance, if a chargeback costs{' '}
              {currencySymbol}10 and you want to charge the merchant {currencySymbol}25, you have
              two options:
            </p>

            <ul className="my-2 ml-5 list-disc">
              <li>
                Pay the {currencySymbol}10 yourself and bill the merchant an additional{' '}
                {currencySymbol}25. Using the "We Pay" option and adding the {currencySymbol}25 to
                the "Additional Fee" field.
              </li>
              <li>
                Have the merchant pay the {currencySymbol}10 and an extra {currencySymbol}15,
                resulting in two separate transactions. Use the "Merchant Pays" option and add the{' '}
                {currencySymbol}15 to the "Additional Fee" field. Note: the merchant will see them
                as two separate transactions.
              </li>
            </ul>

            <p>
              Extra fees are charged either at the time of the chargeback or as soon as funds are
              available.
            </p>
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <div className="flex items-center gap-x-16">
            <InputRadio
              // TODO: Clean up after 1.9.0 convert to chargebackFeeModel
              {...register('chargebackFeesModel')}
              value="TENANT_PAY"
              label={<Trans>We pay</Trans>}
              readOnly={readOnly}
              disabled={readOnly}
            />
            <InputRadio
              // TODO: Clean up after 1.9.0 convert to chargebackFeeModel
              {...register('chargebackFeesModel')}
              value="MERCHANT_PAY"
              label={<Trans>Merchant Pays</Trans>}
              readOnly={readOnly}
              disabled={readOnly}
            />
          </div>

          <Label text={t`Additional fee Charged Per Occurrence ${currencySymbol}`}>
            <InputText
              {...register('additionalChargebackFee')}
              addonBefore={currencySymbol}
              value={
                readOnly && additionalChargebackFee
                  ? additionalChargebackFee.toFixed(2)
                  : additionalChargebackFee
              }
              readOnly={readOnly}
              disabled={readOnly}
              className={readOnlyClassName}
              hasError={Boolean(errors.additionalChargebackFee)}
            />
            <ValidationError isVisible={Boolean(errors.additionalChargebackFee)}>
              {errors.additionalChargebackFee?.message}
            </ValidationError>
          </Label>
        </div>
      </Section>
      <Section
        title={<Trans>Refund Split</Trans>}
        subTitle={
          <Trans>
            The refund split determines the source account(s) from which the refund amount is
            issued.
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <InputRadio
            {...register('refundSplitModel')}
            value="MERCHANT_PAY"
            label={
              <span className="flex items-center gap-2">
                <div className="min-w-28">
                  <Trans>Merchant Pays</Trans>
                </div>
                <Typography variant="field-helper" className="pt-0.5">
                  <Trans>Full refund amount is deducted from the merchant.</Trans>
                </Typography>
              </span>
            }
            readOnly={readOnly}
            disabled={readOnly}
          />
          <InputRadio
            {...register('refundSplitModel')}
            value="REVERSE_PAYMENT_SPLIT"
            label={
              <span className="flex items-center gap-2">
                <div className="min-w-28">
                  <Trans>Reverse Payment Split</Trans>
                </div>

                <Typography variant="field-helper" className="pt-0.5">
                  <Trans>
                    Deducts evenly in the same way the original transaction occurred. For example,
                    if the original transaction of {currencySymbol}100 was split with{' '}
                    {currencySymbol}97 going to the merchant and {currencySymbol}3 commission to
                    you, the reversal will also follow this split. In a refund, {currencySymbol}97
                    would be returned from the merchant's account and {currencySymbol}3 from the
                    liable's account.
                  </Trans>
                </Typography>
              </span>
            }
            readOnly={readOnly}
            disabled={readOnly}
          />
        </div>
      </Section>
      <Section
        title={<Trans>Refund Fees</Trans>}
        subTitle={
          <Trans>
            <p>
              Similar to chargebacks, Adyen charges a small fee for refunds. You can either cover
              these fees yourself or pass them on to the merchant. Additionally, you can impose an
              extra fee per transaction on the merchant. For instance, if a fee costs{' '}
              {currencySymbol}1 and you want to charge the merchant {currencySymbol}3, you have two
              options:
            </p>

            <ul className="my-2 ml-5 list-disc">
              <li>
                Pay the {currencySymbol}1 yourself and bill the merchant an additional{' '}
                {currencySymbol}3. Using the "We Pay" option and adding the {currencySymbol}3 to the
                "Additional Fee" field.
              </li>
              <li>
                Have the merchant pay the {currencySymbol}1 and an extra {currencySymbol}2,
                resulting in two separate transactions. Use the "Merchant Pays" option and add the{' '}
                {currencySymbol}2 to the "Additional Fee" field. Note: the merchant will see them as
                two separate transactions.
              </li>
            </ul>

            <p>
              Extra fees are charged either at the time of a refund or as soon as funds are
              available.
            </p>
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <InputRadio
            {...register('refundFeesModel')}
            value="TENANT_PAY"
            label={<Trans>We pay</Trans>}
            readOnly={readOnly}
            disabled={readOnly}
          />
          <InputRadio
            {...register('refundFeesModel')}
            value="MERCHANT_PAY"
            label={<Trans>Merchant Pays</Trans>}
            readOnly={readOnly}
            disabled={readOnly}
          />

          <Label text={t`Additional fee Charged Per Occurrence ${currencySymbol}`}>
            <InputText
              {...register('additionalRefundFee')}
              value={
                readOnly && additionalRefundFee
                  ? additionalRefundFee.toFixed(2)
                  : additionalRefundFee
              }
              readOnly={readOnly}
              disabled={readOnly}
              className={readOnlyClassName}
              addonBefore={currencySymbol}
              hasError={Boolean(errors.additionalRefundFee)}
            />
            <ValidationError isVisible={Boolean(errors.additionalRefundFee)}>
              {errors.additionalRefundFee?.message}
            </ValidationError>
          </Label>
        </div>
      </Section>
      <OverridePricingSection
        html={html}
        readOnly={readOnly}
        register={register}
        showOverrideFeeInput={showOverrideFeeInput}
      />
      <Section
        title={<Trans>Per Device Fee</Trans>}
        subTitle={
          <Trans>
            This is an optional monthly fee that will be charged per terminal or other device
            assigned to a merchant. Fee will be applied on the 1st of each month.
          </Trans>
        }
      >
        <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
          <Label text={t`Fee Amount (${currencySymbol})`}>
            <InputText
              {...register('perDeviceFee')}
              value={readOnly && perDeviceFee ? perDeviceFee.toFixed(2) : perDeviceFee}
              readOnly={readOnly}
              disabled={readOnly}
              className={readOnlyClassName}
              addonBefore={currencySymbol}
              hasError={Boolean(errors.perDeviceFee)}
            />
            <ValidationError isVisible={Boolean(errors.perDeviceFee)}>
              {errors.perDeviceFee?.message}
            </ValidationError>
          </Label>
        </div>
      </Section>
      {tenantIntervalFees.length > 0 && (
        <Section
          title={<Trans>Recurring Fees</Trans>}
          subTitle={
            <Trans>
              This is an optional custom fee that can be charged to your customers once per month or
              annualy. Fee will be applied on the 1st of each month.
            </Trans>
          }
        >
          <div className="flex flex-col divide-y divide-gray-200 gap-y-5">
            {tenantIntervalFees.map((_tenantIntervalFees, tenantIntervalFeeId) => (
              <div
                className={cn('flex flex-col', tenantIntervalFeeId > 0 ? 'pt-5' : '')}
                key={`tenantIntervalFeeId-${tenantIntervalFeeId}`}
              >
                <div className="flex gap-x-8">
                  <div className="flex flex-col w-full gap-6 lg:w-1/2 2xl:w-1/3">
                    <Label text={t`Fee Name`}>
                      <InputText
                        {...register(`tenantIntervalFees.${tenantIntervalFeeId}.name`)}
                        hasError={Boolean(errors.tenantIntervalFees?.[tenantIntervalFeeId]?.name)}
                        placeholder={t`e.g. Monthly Maintenance Fee & Account Fee`}
                        readOnly={readOnly}
                        disabled={readOnly}
                        className={readOnlyClassName}
                      />
                      <ValidationError
                        isVisible={Boolean(errors.tenantIntervalFees?.[tenantIntervalFeeId]?.name)}
                      >
                        {errors.tenantIntervalFees?.[tenantIntervalFeeId]?.name?.message}
                      </ValidationError>
                    </Label>
                    <Controller
                      control={control}
                      defaultValue="MONTHLY"
                      name={`tenantIntervalFees.${tenantIntervalFeeId}.period`}
                      render={({field}) => (
                        <>
                          <InputSelect
                            label={t`Billing Frequency`}
                            value={field.value}
                            options={[
                              {
                                id: 'MONTHLY',
                                label: t`Monthly`,
                              },
                              {
                                id: 'ANNUAL',
                                label: t`Annual`,
                              },
                            ]}
                            onChange={value => {
                              field.onChange(value);
                            }}
                            disabled={readOnly}
                          />
                          <ValidationError
                            isVisible={Boolean(
                              errors.tenantIntervalFees?.[tenantIntervalFeeId]?.period?.message
                            )}
                          >
                            {errors.tenantIntervalFees?.[tenantIntervalFeeId]?.period?.message}
                          </ValidationError>
                        </>
                      )}
                    />
                    {tenantIntervalFees[tenantIntervalFeeId]?.period === 'ANNUAL' && (
                      <Controller
                        control={control}
                        defaultValue="0"
                        name={`tenantIntervalFees.${tenantIntervalFeeId}.annualFeeCollectMonth`}
                        render={({field}) => (
                          <>
                            <InputSelect
                              label={t`Billing Month`}
                              value={field.value}
                              options={getMonthSelectOptions()}
                              onChange={value => {
                                field.onChange(value);
                              }}
                              disabled={readOnly}
                            />
                            <ValidationError
                              isVisible={Boolean(
                                errors.tenantIntervalFees?.[tenantIntervalFeeId]
                                  ?.annualFeeCollectMonth?.message
                              )}
                            >
                              {
                                errors.tenantIntervalFees?.[tenantIntervalFeeId]
                                  ?.annualFeeCollectMonth?.message
                              }
                            </ValidationError>
                          </>
                        )}
                      />
                    )}
                    <Label text={t`Fee Amount (${currencySymbol})`}>
                      <InputText
                        {...register(`tenantIntervalFees.${tenantIntervalFeeId}.amount`)}
                        value={
                          readOnly
                            ? Number(
                                getValues(`tenantIntervalFees.${tenantIntervalFeeId}.amount`)
                              ).toFixed(2)
                            : getValues(`tenantIntervalFees.${tenantIntervalFeeId}.amount`)
                        }
                        addonBefore={currencySymbol}
                        readOnly={readOnly}
                        disabled={readOnly}
                        className={readOnlyClassName}
                        hasError={Boolean(errors.tenantIntervalFees?.[tenantIntervalFeeId]?.amount)}
                      />
                      <ValidationError
                        isVisible={Boolean(
                          errors.tenantIntervalFees?.[tenantIntervalFeeId]?.amount
                        )}
                      >
                        {errors.tenantIntervalFees?.[tenantIntervalFeeId]?.amount?.message}
                      </ValidationError>
                    </Label>
                    {!readOnly && tenantIntervalFeeId > 0 && (
                      <Button
                        size="lg"
                        className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
                        type="button"
                        onClick={() => onRemoveTenantIntervalFee(tenantIntervalFeeId)}
                      >
                        <Trans>Remove Fee</Trans>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {tenantIntervalFees.length < tenantIntervalFeesLimit && !readOnly && (
              <div>
                <Button
                  size="lg"
                  className="mt-6 w-fit"
                  type="button"
                  onClick={onAddNewTenantIntervalFee}
                >
                  <Trans>Add New</Trans>
                </Button>
              </div>
            )}
          </div>
        </Section>
      )}
    </div>
  );
};
