import {useLingui} from '@lingui/react';
import {SplitConfigurationGroupListItemOutput} from '@zentact/api/src/trpc/routers/splitConfigurationGroupRouter';
import {MerchantAccountPublicStatus, MerchantsFilters} from '@zentact/common';
import {useCallback} from 'react';
import {merchantAccountStatusToColor} from '../layout';
import {getLocalizedStatusMap} from '../localizedMaps';
import {ResetTableFiltersButton} from '../table';
import {CheckboxFilter} from './CheckboxFilter';
import {EntityPickerFilter} from './EntityPickerFilter';
import {FlatPillWithDot} from './FlatPillWithDot';
import {InputSearchFilter} from './InputSearchFilter';
import {InputSearchSelectFilter} from './InputSearchSelectFilter';

type Props<T extends MerchantsFilters> = {
  typedSearchParams: T;
  setTypedSearchParams: (params: T) => void;
  status?: string[];
  reservesApplied?: string;
  organizationId?: string;
  businessName?: string;
  feeGroupId?: string;
  feeGroups?: SplitConfigurationGroupListItemOutput[];
  organizationList?: {id: string; name: string}[];
  enableManagedRisk?: boolean;
};

export const MerchantFilters = <T extends MerchantsFilters>({
  typedSearchParams,
  setTypedSearchParams,
  status,
  reservesApplied,
  organizationId,
  businessName,
  organizationList,
  feeGroupId,
  feeGroups,
  enableManagedRisk,
}: Props<T>) => {
  const {i18n} = useLingui();

  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({
        pageIndex: undefined,
        pageSize: undefined,
        [key]: value,
      } as unknown as T);
    },
    [setTypedSearchParams]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      <div className="block lg:hidden 2xl:block max-lg:w-full">
        <ResetTableFiltersButton
          defaultFilters={{}}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {organizationList && (
          <EntityPickerFilter
            selected={organizationId || ''}
            onChange={value => handleFilterChange('organizationId', value)}
            options={organizationList}
          />
        )}
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <InputSearchFilter
          label="Merchant"
          selected={businessName}
          onChange={value => handleFilterChange('businessName', value)}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {!!feeGroups?.length && (
          <div className="font-normal shrink-0 max-lg:w-full">
            <InputSearchSelectFilter
              className="w-full mt-0"
              labelClassName="gap-0"
              value={feeGroupId}
              onChange={val =>
                handleFilterChange(
                  'feeGroupId',
                  Array.isArray(val) ? (val[val.length - 1] as string) : val ?? undefined
                )
              }
              options={feeGroups.map(group => ({id: group.id, label: group.name}))}
              placeholder={i18n._('Fee group')}
            />
          </div>
        )}
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        <CheckboxFilter
          label={i18n._('Status')}
          contentClass="right-0 overflow-x-hidden"
          selected={status}
          onChange={value => handleFilterChange('status', value)}
          options={Object.keys(merchantAccountStatusToColor).map(status => ({
            element: (
              <FlatPillWithDot
                color={
                  merchantAccountStatusToColor[status as MerchantAccountPublicStatus] || 'blue'
                }
                label={getLocalizedStatusMap(i18n)[status as MerchantAccountPublicStatus]}
              />
            ),
            key: status,
          }))}
        />
      </div>
      {enableManagedRisk && (
        <div className="font-normal shrink-0 max-lg:w-full">
          <CheckboxFilter
            label={i18n._('Managed Risk')}
            contentClass="right-0 overflow-x-hidden"
            selected={reservesApplied ? ['reservesApplied'] : undefined}
            onChange={value => {
              handleFilterChange('reservesApplied', value?.length ? 'true' : undefined);
            }}
            options={['reservesApplied'].map(status => ({
              element: (
                <div className="flex items-center gap-1 whitespace-nowrap">
                  {i18n._('Reserves Applied')}
                </div>
              ),
              key: status,
            }))}
          />
        </div>
      )}
    </div>
  );
};
