import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  Button,
  InputSelect,
  InputText,
  Label,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {Controller, useForm} from 'react-hook-form';
import z from 'zod';

const businessAddressFormSchema = () =>
  z
    .object({
      businessAddressCountry: z.string().min(1, t`Country is required`),
      businessAddressLine1: z.string().min(1, t`Line 1 is required`),
      businessAddressLine2: z.string().optional(),
      businessAddressCity: z.string().min(1, t`City is required`),
      businessAddressState: z
        .union([
          z
            .string()
            .min(2, t`State or Province is required`)
            .max(3, t`Please use two or three letter abbreviation for the state`),
          z.string().length(0),
        ])

        .optional(),
      //Some countrise like UK don't have states or provinces
      businessAddressPostalCode: z.string().min(1, t`Postal code is required`),
    })
    .superRefine((data, ctx) => {
      if (data.businessAddressCountry !== 'GB' && !data.businessAddressState) {
        ctx.addIssue({
          path: ['businessAddressState'],
          code: z.ZodIssueCode.custom,
          message: t`State or Province is required`,
        });
      }
    });

export type BusinessAddressFormData = z.infer<ReturnType<typeof businessAddressFormSchema>>;

type Props = {
  defaultValues: {
    businessAddressCountry: string;
    businessAddressLine1: string;
    businessAddressLine2: string;
    businessAddressCity: string;
    businessAddressState: string;
    businessAddressPostalCode: string;
  };
};

export const BusinessAddressForm = ({defaultValues}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {
    handleSubmit,
    formState: {errors},
    control,
    register,
    watch,
  } = useForm<BusinessAddressFormData>({
    resolver: zodResolver(businessAddressFormSchema()),
    defaultValues: {
      businessAddressCountry: defaultValues.businessAddressCountry,
      businessAddressLine1: defaultValues.businessAddressLine1,
      businessAddressLine2: defaultValues.businessAddressLine2,
      businessAddressCity: defaultValues.businessAddressCity,
      businessAddressState: defaultValues.businessAddressState,
      businessAddressPostalCode: defaultValues.businessAddressPostalCode,
    },
  });

  const country = watch('businessAddressCountry');

  const trpcContext = trpc.useUtils();

  const updateTenantBrandConfiguration = trpc.tenant.updateBranding.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      showSuccessNotification(t`Business address have been updated`);
    },
    onError: () => {
      showErrorNotification(
        t`Failed to update biusiness address`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const submitForm = async (values: BusinessAddressFormData) => {
    updateTenantBrandConfiguration.mutate({
      ...values,
      businessAddressState:
        values.businessAddressCountry !== 'GB' ? values.businessAddressState : null,
    });
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit(submitForm)}>
      <div className="px-4 pb-5 sm:px-6">
        <div className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <Controller
              name="businessAddressCountry"
              control={control}
              render={({field}) => (
                <InputSelect
                  {...field}
                  className="mb-2"
                  label={t`Country`}
                  options={[
                    {
                      id: 'US',
                      label: t`United States`,
                    },
                    {
                      id: 'CA',
                      label: t`Canada`,
                    },
                    {
                      id: 'AU',
                      label: t`Australia`,
                    },
                    {
                      id: 'GB',
                      label: t`United Kingdom`,
                    },
                    {
                      id: 'NZ',
                      label: t`New Zealand`,
                    },
                  ]}
                  placeholder={t`Please select country`}
                />
              )}
            />
            <ValidationError isVisible={Boolean(errors.businessAddressCountry)}>
              {errors.businessAddressCountry?.message}
            </ValidationError>
          </div>
          <div className="col-span-full">
            <Label text={t`Line 1`}>
              <InputText {...register('businessAddressLine1', {required: true})} />
              <ValidationError isVisible={Boolean(errors.businessAddressLine1)}>
                {errors.businessAddressLine1?.message}
              </ValidationError>
            </Label>
          </div>
          <div className="col-span-full">
            <Label text={t`Line 2`}>
              <InputText {...register('businessAddressLine2', {required: true})} />
              <ValidationError isVisible={Boolean(errors.businessAddressLine2)}>
                {errors.businessAddressLine2?.message}
              </ValidationError>
            </Label>
          </div>
          <div className="sm:col-span-2 sm:col-start-1">
            <Label text={t`City`}>
              <InputText {...register('businessAddressCity', {required: true})} />
              <ValidationError isVisible={Boolean(errors.businessAddressCity)}>
                {errors.businessAddressCity?.message}
              </ValidationError>
            </Label>
          </div>
          {country !== 'GB' && (
            <div className="sm:col-span-2">
              <Label text={t`State / Province`}>
                <InputText {...register('businessAddressState', {required: true})} />
                <ValidationError isVisible={Boolean(errors.businessAddressState)}>
                  {errors.businessAddressState?.message}
                </ValidationError>
              </Label>
            </div>
          )}

          <div className="sm:col-span-2">
            <Label text={t`ZIP / Postal code`}>
              <InputText {...register('businessAddressPostalCode', {required: true})} />
              <ValidationError isVisible={Boolean(errors.businessAddressPostalCode)}>
                {errors.businessAddressPostalCode?.message}
              </ValidationError>
            </Label>
          </div>
        </div>
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={updateTenantBrandConfiguration.isLoading}
            isLoading={updateTenantBrandConfiguration.isLoading}
          >
            <Trans>Save</Trans>
          </Button>
        </footer>
      </div>
    </form>
  );
};
