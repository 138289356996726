import {trpc} from '@/api/trpcClient';
import {i18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {InternalTransfersListItem} from '@zentact/api/src/trpc/routers/balanceAccountRouter';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';

type Props = {
  row: InternalTransfersListItem;
  refetch: () => void;
};

export const InternalTransfersActions = ({row, refetch}: Props) => {
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const cancelMutation = trpc.balanceAccount.cancelInternalTransfer.useMutation({
    onSuccess: () => {
      refetch();
      setCancelConfirmationOpen(false);
      showSuccessNotification(
        t`Transfer canceled`,
        t`You have successfully canceled the scheduled transfer.`
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const handleCancel = useCallback(() => {
    cancelMutation.mutate({
      referenceId: row.referenceId,
    });
  }, [row]);

  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    {
      name: 'Cancel',
      onClick: () => {
        setCancelConfirmationOpen(true);
      },
      itemClassName: 'text-red-500',
    },
  ];
  if (row.status !== 'SCHEDULED') {
    // only scheduled transfers can be canceled
    return null;
  }
  return (
    <>
      <DropDownMinimalMenuIcon items={options} />
      {cancelConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={cancelConfirmationOpen}
          setOpen={setCancelConfirmationOpen}
          handleAction={handleCancel}
          localeText={{
            title: t`Cancel Transfer`,
            description: t`Are you sure you want to cancel this transfer?`,
            confirm: t`Confirm`,
            cancel: t`Back`,
          }}
          loading={cancelMutation.isLoading}
        />
      )}
    </>
  );
};
