import {Trans} from '@lingui/macro';
import {StoreDetailsOutput} from '@zentact/api/src/trpc/routers/storeRouter';
import {DropDownMinimalMenuIcon} from '@zentact/ui-tailwind';

type Props = {
  row: StoreDetailsOutput | null;
  openAssignStoreFeeGroupPanel: (row: StoreDetailsOutput | null) => void;
};

export const StoreActions = ({row, openAssignStoreFeeGroupPanel}: Props) => {
  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    {name: 'Assign Fee Group', onClick: () => openAssignStoreFeeGroupPanel(row)},
  ];
  return <DropDownMinimalMenuIcon items={options} buttonContent={<Trans>Actions</Trans>} />;
};
