import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  formatLocaleDate,
  formatPercentage,
  getPaymentMethodName,
  getPaymentSourceName,
} from '@zentact/common';
import type {MerchantAccountStatus, PaymentSource} from '@zentact/db';
import {
  Breadcrumbs,
  Button,
  DashboardDatepicker,
  EntityPicker,
  InterchangePaymentsList,
  MerchantAccountsPicker,
  RowFilters,
  RowFiltersProps,
  Summary,
  SummaryItemDataType,
  SummaryItemPropType,
  Typography,
  getDashboardDatepickerDefaults,
  useNotification,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useCallback, useEffect, useState} from 'react';
import * as z from 'zod';
import {exportPaymentInterchangeCSV} from './export-payment-interchange-csv';

const getBreadCrumbs = () => [
  {name: t`Reports`, href: RoutePath.REPORTS, current: false, disabled: true},
  {name: t`Interchange Report`, href: RoutePath.REPORTS_INTERCHANGE, current: true},
];

const searchParamsSchema = z.object({
  organizationId: z.string().nullable().optional(),
  merchantAccountId: z.string().nullable().optional(),
  startDate: z
    .date()
    .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
    .nullable()
    .optional(),
  endDate: z
    .date()
    .or(
      z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
    )
    .nullable()
    .optional(),
  feeName: z.string().nullable().optional(),
  sortColumnId: z.string().nullable().optional(),
  sortValue: z.literal('asc').or(z.literal('desc')).nullable().optional(),
  paymentMethods: z.string().optional(),
  sources: z.string().optional(),
});

export const InterchangeReportPage = () => {
  const {pspMerchantAccountName, currency} = useStore();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {i18n} = useLingui();
  const {orgsWithBoardedMerchants: organizationList, locale} = useStore();
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const [isCsvLoading, setCsvLoading] = useState(false);
  const [paymentMethodFilters, setPaymentMethodFilters] = useState<RowFiltersProps['items']>();
  const [paymentSourceFilters, setPaymentSourceFilters] = useState<RowFiltersProps['items']>();

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const selectedOrganization = typedSearchParams?.organizationId;
  const selectedMerchant = typedSearchParams?.merchantAccountId ?? undefined;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();
  const sort =
    typedSearchParams.sortColumnId && typedSearchParams.sortValue
      ? {
          columnId: typedSearchParams.sortColumnId,
          value: typedSearchParams.sortValue,
        }
      : null;

  const trpcContext = trpc.useUtils();

  const selectedPaymentMethodFilters = paymentMethodFilters?.find(item => item.isActive)
    ? paymentMethodFilters.filter(item => item.isActive).map(item => item.key)
    : undefined;
  const selectedSourceFilters = paymentSourceFilters?.find(item => item.isActive)
    ? (paymentSourceFilters.filter(item => item.isActive).map(item => item.key) as PaymentSource[])
    : undefined;

  const {data: summaryData, isLoading: summaryIsLoading} =
    trpc.payment.interchangePaymentSummary.useQuery({
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
      paymentMethods: selectedPaymentMethodFilters,
      sources: selectedSourceFilters,
      merchantAccountId: selectedMerchant,
      pspMerchantAccountName,
    });

  const paymentsFilters = {
    ...(sort && {orderBy: {columnId: sort.columnId, value: sort.value}}),
    where: {
      ...(typedSearchParams.feeName && {feeName: typedSearchParams.feeName}),
      fromDate: dateValue.startDate,
      toDate: dateValue.endDate,
      organizationIds: selectedOrganization ? [selectedOrganization] : undefined,
      paymentMethod: selectedPaymentMethodFilters,
      merchantAccountId: selectedMerchant,
      source: selectedSourceFilters,
      pspMerchantAccountName,
    },
  };

  const paymentList = trpc.payment.interchangePaymentList.useQuery(
    {
      ...paymentsFilters,
      ...pagination,
    },
    {keepPreviousData: true, refetchOnWindowFocus: true}
  );

  const merchantList =
    trpc.merchantAccount.getMerchantAccountsList
      .useQuery(
        {
          where: {
            ...(pspMerchantAccountName && {pspMerchantAccountName}),
            ...(selectedOrganization && {organizationId: selectedOrganization}),
          },
        },
        {enabled: !!pspMerchantAccountName}
      )
      .data?.rows.reduce<{status: MerchantAccountStatus; businessName: string; id: string}[]>(
        (acc, {merchantAccount}) => {
          if (merchantAccount?.primaryStore?.id && merchantAccount.businessName) {
            acc.push({
              status: merchantAccount.status,
              businessName: merchantAccount.businessName,
              id: merchantAccount.id,
            });
          }
          return acc;
        },
        []
      ) || [];

  useEffect(() => {
    if (summaryData?.paymentMethodStats) {
      const activePaymentMethdodsFromURL = new Set(
        typedSearchParams.paymentMethods ? typedSearchParams.paymentMethods.split(',') : []
      );
      setPaymentMethodFilters(
        summaryData.paymentMethodStats.map(item => ({
          key: item.paymentMethod,
          label: `${getPaymentMethodName(item.paymentMethod, locale)} (${item._count})`,
          isActive: activePaymentMethdodsFromURL.has(item.paymentMethod),
        }))
      );
    }
  }, [summaryData?.paymentMethodStats, typedSearchParams.paymentMethods]);

  useEffect(() => {
    if (summaryData?.sourceStats) {
      const activeSourcesFromURL = new Set(
        typedSearchParams.sources ? typedSearchParams.sources.split(',') : []
      );
      setPaymentSourceFilters(
        summaryData.sourceStats.map(item => ({
          key: item.source,
          label: `${getPaymentSourceName(item.source, locale)} (${item._count})`,
          isActive: activeSourcesFromURL.has(item.source),
        }))
      );
    }
  }, [summaryData?.sourceStats, typedSearchParams.sources]);

  const summaryItems: SummaryItemPropType[] = [
    {
      title: t`Average Fee, ${
        summaryData?.enhancedSchemeDataLevelStats.total.count ?? 0
      } Transactions`,
      value: formatPercentage(
        summaryData?.enhancedSchemeDataLevelStats.total.authorizedAmount ?? 0,
        summaryData?.enhancedSchemeDataLevelStats.total.interchangeAmount ?? 0,
        locale
      ),
      type: SummaryItemDataType.STRING,
    },
    {
      title: t`Level: None, ${
        summaryData?.enhancedSchemeDataLevelStats.none.count ?? 0
      } Transactions`,
      value: formatPercentage(
        summaryData?.enhancedSchemeDataLevelStats.none.authorizedAmount ?? 0,
        summaryData?.enhancedSchemeDataLevelStats.none.interchangeAmount ?? 0,
        locale
      ),
      type: SummaryItemDataType.STRING,
    },
    {
      title: t`Level: L2, ${summaryData?.enhancedSchemeDataLevelStats.l2.count ?? 0} Transactions`,
      value: formatPercentage(
        summaryData?.enhancedSchemeDataLevelStats.l2.authorizedAmount ?? 0,
        summaryData?.enhancedSchemeDataLevelStats.l2.interchangeAmount ?? 0,
        locale
      ),
      type: SummaryItemDataType.STRING,
    },
    {
      title: t`Level: L3, ${summaryData?.enhancedSchemeDataLevelStats.l3.count ?? 0} Transactions`,
      value: formatPercentage(
        summaryData?.enhancedSchemeDataLevelStats.l3.authorizedAmount ?? 0,
        summaryData?.enhancedSchemeDataLevelStats.l3.interchangeAmount ?? 0,
        locale
      ),
      type: SummaryItemDataType.STRING,
    },
  ];

  const handleOrganizationChange = useCallback(
    (organizationId?: string) => {
      setTypedSearchParams({organizationId});
      setPagination({pageIndex: 0, pageSize: 25});
    },
    [setTypedSearchParams, setPagination]
  );

  const handleMerchantChange = useCallback(
    (merchantAccountId?: string) => {
      setTypedSearchParams({merchantAccountId});
      setPagination({pageIndex: 0, pageSize: 25});
    },
    [setTypedSearchParams, setPagination]
  );

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams(dateRange);
      setPagination({pageIndex: 0, pageSize: 25});
    },
    [setTypedSearchParams, setPagination]
  );

  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullList = await trpcContext.payment.interchangePaymentList.fetch({
        ...paymentsFilters,
        ...pagination,
      });
      exportPaymentInterchangeCSV(fullList, dateValue.startDate, dateValue.endDate, i18n);
      showSuccessNotification(t`Interchange .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Interchange .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, exportPaymentInterchangeCSV, i18n, paymentsFilters]);

  const showPaymentMethodsFilter = !!paymentMethodFilters && paymentMethodFilters.length > 1;
  const showSourcesFilter = !!paymentSourceFilters && paymentSourceFilters.length > 1;

  useEffect(() => {
    const isOrganizationMerchant = merchantList.some(
      merchant => merchant.id === typedSearchParams.merchantAccountId
    );

    if (
      typedSearchParams.organizationId &&
      typedSearchParams.merchantAccountId &&
      !isOrganizationMerchant
    ) {
      setTypedSearchParams({merchantAccountId: null});
    }
  }, [setTypedSearchParams, typedSearchParams.organizationId, typedSearchParams.merchantAccountId]);

  return (
    <>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex flex-col flex-wrap gap-2 pt-4 lg:justify-between lg:items-center lg:flex-row">
        <div className="md:whitespace-nowrap">
          <Typography variant="header-page">
            <Trans>Transaction Interchange Report For</Trans>{' '}
            {formatLocaleDate(dateValue.startDate, 'short')}
            {' - '}
            {formatLocaleDate(dateValue.endDate, 'short')}
          </Typography>
        </div>
        <div className="flex flex-col gap-2 mt-4 lg:items-center lg:flex-row">
          <div className="w-full shrink-0 lg:w-60">
            <MerchantAccountsPicker
              merchantAccountsOptions={merchantList}
              allLabel={t`All Merchants`}
              selectedMerchantAccount={selectedMerchant}
              onSelectMerchantAccount={handleMerchantChange}
            />
          </div>
          <div className="w-full shrink-0 lg:w-60">
            <EntityPicker
              selected={selectedOrganization || undefined}
              onChange={handleOrganizationChange}
              options={organizationList}
              className="w-full"
            />
          </div>
          <div className="w-64 max-lg:w-full shrink-0">
            <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit whitespace-nowrap max-lg:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!paymentList.data || paymentList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      {(showPaymentMethodsFilter || showSourcesFilter) && (
        <div className="flex mt-5 md:items-center gap-x-3 gap-y-2 max-md:flex-col max-md:justify-center">
          <div className="flex text-sm">
            <Trans>Filters:</Trans>
          </div>
          {showPaymentMethodsFilter && (
            <RowFilters
              items={paymentMethodFilters}
              onChange={items => {
                setPaymentMethodFilters(items);
                const activePaymentMethods = items
                  .filter(item => item.isActive)
                  .map(item => item.key);

                setTypedSearchParams({paymentMethods: activePaymentMethods.join(',')});
              }}
            />
          )}
          {showSourcesFilter && (
            <RowFilters
              items={paymentSourceFilters}
              onChange={items => {
                setPaymentSourceFilters(items);
                const activeSources = items.filter(item => item.isActive).map(item => item.key);
                setTypedSearchParams({sources: activeSources.join(',')});
              }}
            />
          )}
        </div>
      )}
      <Summary
        summaryItems={summaryItems}
        isLoading={summaryIsLoading}
        locale={locale}
        currency={currency}
      />
      {!!summaryData?.noInterchangeDataTxCount && (
        <div className="flex justify-end mt-4 text-sm text-gray-700">
          <Trans>
            * This range has {summaryData.noInterchangeDataTxCount} unsettled authorizations
          </Trans>
        </div>
      )}
      <div className="mt-4 overflow-x-auto">
        <InterchangePaymentsList
          paymentsList={paymentList.data}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={newSort => {
            setTypedSearchParams({
              sortColumnId: newSort?.columnId,
              sortValue: newSort?.value,
            });
          }}
          pagination={pagination}
          onPaginationChange={setPagination}
          isLoading={
            paymentList.isLoading || (paymentList.isRefetching && paymentList.isPreviousData)
          }
        />
      </div>
    </>
  );
};
