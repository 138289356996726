import {RoutePath} from '@/components/layout/navigation';
import {CheckIcon} from '@heroicons/react/20/solid';
import {Trans, t} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {
  SplitConfigurationGroupListItemOutput,
  TenantDefaultSplitConfigurationGroupOutput,
} from '@zentact/api/src/trpc/routers/splitConfigurationGroupRouter';
import {TableColumnSize, formatLocaleDate} from '@zentact/common';
import type {SplitConfigurationGroupStatus} from '@zentact/db';
import {
  FlatPillWithDot,
  HighlightedText,
  TableCheckboxFilter,
  TableSearchFilter,
  getTableMeta,
} from '@zentact/ui-tailwind';
import {Link, generatePath} from 'react-router-dom';
import {SplitConfigurationGroupActions} from './split-configuration-group-actions';

type PillProps = React.ComponentProps<typeof FlatPillWithDot>;
type SplitConfigurationGroupStatusToColor = {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  [key in SplitConfigurationGroupStatus]: PillProps['color'];
};

export const splitConfigurationGroupStatusToColor: SplitConfigurationGroupStatusToColor = {
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ACTIVE: 'green',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  INACTIVE: 'yellow',
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ARCHIVED: 'gray',
};

const getLocalizedStatusMap = (): Record<SplitConfigurationGroupStatus, string> => ({
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ACTIVE: t`Active`,
  // biome-ignore lint/style/useNamingConvention: <explanation>
  INACTIVE: t`Inactive`,
  // biome-ignore lint/style/useNamingConvention: <explanation>
  ARCHIVED: t`Archived`,
});

const columnsHelper = createColumnHelper<SplitConfigurationGroupListItemOutput>();

type Props = {
  refetchSplitConfigurationGroupsList: () => void;
  openDetails?: (splitConfigurationGroupId: string) => void;
  defaultSplitConfigurationGroup?: TenantDefaultSplitConfigurationGroupOutput;
};
export const getSplitConfigurationGroupsColumns = ({
  refetchSplitConfigurationGroupsList,
  openDetails,
  defaultSplitConfigurationGroup,
}: Props) => {
  const localizedStatusMap = getLocalizedStatusMap();
  return [
    columnsHelper.display({
      cell: props => {
        const {filterValues} = getTableMeta(props.table);
        const searchString = filterValues?.name as string;
        const textContent = props.row.original.name || '-';

        return (
          <HighlightedText
            text={textContent}
            highlight={searchString}
            className="text-wrap line-clamp-1 break-all"
          />
        );
      },
      id: 'name',
      header: () => <Trans>Name</Trans>,
      meta: {
        filter: {
          filterId: 'name',
          renderFilter: ({...filterProps}) => (
            <TableSearchFilter
              {...filterProps}
              inputClassName="absolute -mt-4 w-40 -translate-x-14 transform"
            />
          ),
        },
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.XL,
    }),
    columnsHelper.accessor('id', {
      id: 'id',
      header: () => <Trans>ID</Trans>,
      cell: props => (
        <Link
          to={generatePath(RoutePath.FEE_GROUP_DETAILS, {
            splitConfigurationGroupId: props.row.original.id,
          })}
        >
          {props.row.original.id}
        </Link>
      ),
      meta: {
        visibleAt: 'md',
      },
    }),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={splitConfigurationGroupStatusToColor[info.getValue()] || 'blue'}
          label={localizedStatusMap[info.getValue()] || info.getValue()}
        />
      ),
      meta: {
        filter: {
          filterId: 'status',
          renderFilter: ({onChange, filterId, filterValues}) => {
            return (
              <TableCheckboxFilter
                filterValues={filterValues}
                filterId={filterId}
                onChange={onChange}
                elements={Object.keys(splitConfigurationGroupStatusToColor).map(status => ({
                  element: (
                    <FlatPillWithDot
                      color={
                        splitConfigurationGroupStatusToColor[
                          status as SplitConfigurationGroupStatus
                        ] || 'blue'
                      }
                      label={localizedStatusMap[status as SplitConfigurationGroupStatus]}
                    />
                  ),
                  key: status,
                }))}
              />
            );
          },
        },
        collapseAt: 'sm',
      },
      header: () => <Trans>Status</Trans>,
      size: TableColumnSize.M,
    }),
    columnsHelper.accessor('isDefault', {
      id: 'isDefault',
      header: () => <Trans>Default</Trans>,
      cell: props => (props.row.original.isDefault ? <CheckIcon className="w-6 h-6" /> : ''),
      meta: {
        collapseAt: 'sm',
      },
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor('createdByUserName', {
      id: 'createdByUserName',
      header: () => <Trans>Created By</Trans>,
      meta: {
        visibleAt: 'md',
        sort: {
          isSortable: true,
        },
      },
    }),
    columnsHelper.accessor('createdAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Created At</Trans>,
      meta: {
        collapseAt: 'lg',
        visibleAt: 'md',
        sort: {
          isSortable: true,
        },
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <SplitConfigurationGroupActions
            splitConfigurationGroup={props.row.original}
            refetchData={refetchSplitConfigurationGroupsList}
            openDetails={openDetails}
            defaultSplitConfigurationGroup={defaultSplitConfigurationGroup}
          />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
