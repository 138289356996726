import {trpc} from '@/api/trpcClient';
import {i18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {TenantWebhookListItemOutput} from '@zentact/api/src/trpc/routers/webhookRouter';
import {
  AlertOverlayWithConfirmation,
  DropDownMinimalMenuIcon,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useState} from 'react';

export type WebHookActionsProps = {
  row: TenantWebhookListItemOutput;
  openEditPanel: (row: TenantWebhookListItemOutput) => void;
  refetch: () => void;
};

export const WebhookActions = ({row, openEditPanel, refetch}: WebHookActionsProps) => {
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const deactivateMutation = trpc.webhooks.deactivateTenantWebhook.useMutation({
    onSuccess: () => {
      refetch();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(
        t`Webhook deactivated`,
        t`You have successfully deactivated the Webhook.`
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const activateMutation = trpc.webhooks.activateTenantWebhook.useMutation({
    onSuccess: () => {
      refetch();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(
        t`Webhook activated`,
        t`You have successfully activated the Webhook.`
      );
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const deleteMutation = trpc.webhooks.deleteTenantWebhookConfig.useMutation({
    onSuccess: () => {
      refetch();
      setDeleteConfirmationOpen(false);
      showSuccessNotification(t`Webhook deleted`, t`You have successfully deleted the Webhook.`);
    },
    onError: error => {
      showErrorNotification(i18n._('Error'), error.message);
    },
  });

  const handleDeactivate = useCallback(() => {
    deactivateMutation.mutate({
      id: row.id,
    });
  }, [row]);

  const handleActivate = useCallback(() => {
    activateMutation.mutate({
      id: row.id,
    });
  }, [row]);

  const handleDelete = useCallback(() => {
    deleteMutation.mutate({
      id: row.id,
    });
  }, [row]);

  const options: {name: string; onClick: () => void; itemClassName?: string}[] = [
    ...(!row.enabled
      ? [
          {
            name: 'Activate',
            onClick: () => {
              handleActivate();
            },
          },
        ]
      : []),
    {
      name: 'Edit Webhook',
      onClick: () => {
        openEditPanel(row);
      },
    },
    ...(row.enabled
      ? [
          {
            name: 'Deactivate',
            onClick: () => {
              setDeactivateConfirmationOpen(true);
            },
            itemClassName: 'text-red-500',
          },
        ]
      : []),
    {
      name: 'Delete Webhook',
      onClick: () => {
        setDeleteConfirmationOpen(true);
      },
      itemClassName: 'text-red-500',
    },
  ];
  return (
    <>
      <DropDownMinimalMenuIcon items={options} />
      {deactivateConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deactivateConfirmationOpen}
          setOpen={setDeactivateConfirmationOpen}
          handleAction={handleDeactivate}
          loading={deactivateMutation.isLoading}
          localeText={{
            title: t`Deactivate Webhook`,
            description: t`Are you sure you want to deactivate this Webhook?`,
            confirm: t`Deactivate`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {deleteConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deleteConfirmationOpen}
          setOpen={setDeleteConfirmationOpen}
          handleAction={handleDelete}
          loading={deleteMutation.isLoading}
          localeText={{
            title: t`Delete Webhook`,
            description: t`Are you sure you want to delete this Webhook?`,
            confirm: t`Delete`,
            cancel: t`Cancel`,
          }}
        />
      )}
    </>
  );
};
