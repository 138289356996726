import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  Button,
  InputSelect,
  Typography,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';

export const getUpdateMerchantSettlementAndCaptureDelaySchema = () =>
  z.object({
    settlementDelayDays: z.string({
      // biome-ignore lint/style/useNamingConvention: Zod uses snake case
      required_error: t`Please select Settlement Delay`,
    }),
    captureDelayHours: z.string({
      // biome-ignore lint/style/useNamingConvention: Zod uses snake case
      required_error: t`Please select Capture Delay`,
    }),
  });

export type UpdateMerchantSettlementAndCaptureDelayDaysData = z.infer<
  ReturnType<typeof getUpdateMerchantSettlementAndCaptureDelaySchema>
>;

const options = [
  {
    id: '1',
    label: 'T+1',
  },
  {
    id: '2',
    label: 'T+2',
  },
  {
    id: '3',
    label: 'T+3',
  },
];

const captureDelayOptions = [
  {
    id: '0',
    label: 'Immediate',
  },
  {
    id: '24',
    label: '1 day',
  },
  {
    id: '48',
    label: '2 days',
  },
  {
    id: '72',
    label: '3 days',
  },
];

export const CaptureAndSettlementDelay = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {data: tenant} = trpc.tenant.getContextTenant.useQuery();
  const {tenantRefetch} = useStore();

  const currentSettlementDelay = tenant?.settlementDelayDays?.toString();
  const currentCaptureDelayHours = tenant?.captureDelayHours?.toString();

  const formMethods = useForm<UpdateMerchantSettlementAndCaptureDelayDaysData>({
    resolver: zodResolver(getUpdateMerchantSettlementAndCaptureDelaySchema()),
    defaultValues: {
      settlementDelayDays: currentSettlementDelay,
      captureDelayHours: currentCaptureDelayHours,
    },
  });

  const {
    handleSubmit,
    control,
    formState: {errors},
    watch,
  } = formMethods;
  const trpcContext = trpc.useUtils();

  const settlementDelayDaysWatch = watch('settlementDelayDays');
  const captureDelayHoursWatch = watch('captureDelayHours');

  const updateTenantAdyenConfiguration = trpc.tenant.updateTenantAdyenConfiguration.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      tenantRefetch();
      showSuccessNotification(t`Capture and Settlement Delays were updated`);
    },
    onError: () => {
      showErrorNotification(
        t`Failed to update capture and settlement delays`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const onSubmit = useCallback((data: UpdateMerchantSettlementAndCaptureDelayDaysData) => {
    updateTenantAdyenConfiguration.mutate({
      settlementDelayDays: parseInt(data.settlementDelayDays),
      captureDelayHours: parseInt(data.captureDelayHours),
    });
  }, []);

  return (
    <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
      <div className="px-4 py-5 sm:px-6">
        <Typography variant="header-section">
          <Trans>Capture and Settlement Delay</Trans>
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-col gap-3">
            <Controller
              name="captureDelayHours"
              control={control}
              render={({field}) => (
                <InputSelect
                  {...field}
                  className="mb-2"
                  label={t`Capture Delay`}
                  options={captureDelayOptions}
                  placeholder={t`Please select the value`}
                />
              )}
            />
            <ValidationError isVisible={Boolean(errors.captureDelayHours)}>
              {errors.captureDelayHours?.message}
            </ValidationError>
            <p className="text-xs leading-5 text-gray-500">
              <Trans>Capture delay is the time between payment authorisation and capture.</Trans>
            </p>
          </div>
          <div className="flex flex-col gap-3 mt-4">
            <Controller
              name="settlementDelayDays"
              control={control}
              render={({field}) => (
                <InputSelect
                  {...field}
                  className="mb-2"
                  label={t`Settlement Delay`}
                  options={options}
                  placeholder={t`Please select the value`}
                />
              )}
            />
            <ValidationError isVisible={Boolean(errors.settlementDelayDays)}>
              {errors.settlementDelayDays?.message}
            </ValidationError>
            <p className="text-xs leading-5 text-gray-500">
              <Trans>
                The settlement delay is the (possible) delay, or time it takes for a transaction to
                be Settled (from SentForSettle) or Refunded (from SentForRefund).
              </Trans>
            </p>
          </div>
          <footer className="flex justify-end mt-8">
            <Button
              size="lg"
              type="submit"
              className="w-fit"
              disabled={
                settlementDelayDaysWatch === currentSettlementDelay &&
                captureDelayHoursWatch === currentCaptureDelayHours
              }
              isLoading={updateTenantAdyenConfiguration.isLoading}
            >
              <Trans>Save</Trans>
            </Button>
          </footer>
        </div>
      </form>
    </div>
  );
};
