import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {isValidPhoneNumber} from '@zentact/common';
import {Button, InputPhone, Label, ValidationError, useNotification} from '@zentact/ui-tailwind';
import {useForm} from 'react-hook-form';
import z from 'zod';

const phoneNumberSettingsFormSchema = () =>
  z.object({
    phoneNumber: z
      .string({
        // biome-ignore lint/style/useNamingConvention: <explanation>
        invalid_type_error: t`Phone number is invalid`,
      })
      .refine(
        phoneNumber => {
          if (phoneNumber) {
            return isValidPhoneNumber(phoneNumber);
          }
          return true;
        },
        {
          message: t`Phone number is invalid`,
        }
      ),
  });

export type PhoneNumberSettingsFormData = z.infer<ReturnType<typeof phoneNumberSettingsFormSchema>>;

type Props = {
  defaultValues: {
    phoneNumber: string;
  };
};

export const PhoneNumberSettingsForm = ({defaultValues}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm<PhoneNumberSettingsFormData>({
    resolver: zodResolver(phoneNumberSettingsFormSchema()),
    defaultValues: {
      phoneNumber: defaultValues.phoneNumber,
    },
  });

  const trpcContext = trpc.useUtils();

  const updateTenantBrandConfiguration = trpc.tenant.updateBranding.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      showSuccessNotification(t`Phone number settings have been updated`);
    },
    onError: () => {
      showErrorNotification(
        t`Failed to update phone number settings`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const submitForm = async (values: PhoneNumberSettingsFormData) => {
    updateTenantBrandConfiguration.mutate(values);
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit(submitForm)}>
      <div className="px-4 py-5 space-y-6 sm:px-6">
        <div className="flex flex-col w-full gap-6 md:w-1/2">
          <div className="space-y-4 sm:col-span-3">
            <Label text={t`Phone Number`}>
              <InputPhone name="phoneNumber" control={control} />
              <ValidationError isVisible={Boolean(errors.phoneNumber)}>
                {errors.phoneNumber?.message}
              </ValidationError>
            </Label>
          </div>
        </div>
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={updateTenantBrandConfiguration.isLoading}
            isLoading={updateTenantBrandConfiguration.isLoading}
          >
            <Trans>Save</Trans>
          </Button>
        </footer>
      </div>
    </form>
  );
};
