import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {Button, InputText, Label, ValidationError, useNotification} from '@zentact/ui-tailwind';
import {useForm} from 'react-hook-form';
import z from 'zod';

const emailSettingsFormSchema = () =>
  z.object({
    supportEmail: z
      .string()
      .min(1, t`Please enter a support email address`)
      .email(t`Please enter a valid email address`)
      .max(50, t`Email address must not exceed 50 characters`),
    fromEmail: z
      .string()
      .min(1, t`Please enter a from email address`)
      .email(t`Please enter a valid email address`)
      .max(50, t`Email address must not exceed 50 characters`),
  });

export type EmailSettingsFormData = z.infer<ReturnType<typeof emailSettingsFormSchema>>;

type Props = {
  defaultValues: {
    supportEmail: string;
    fromEmail: string;
  };
};

export const EmailSettingsForm = ({defaultValues}: Props) => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<EmailSettingsFormData>({
    resolver: zodResolver(emailSettingsFormSchema()),
    defaultValues: {
      supportEmail: defaultValues.supportEmail,
      fromEmail: defaultValues.fromEmail,
    },
  });

  const trpcContext = trpc.useUtils();

  const updateTenantBrandConfiguration = trpc.tenant.updateBranding.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
      showSuccessNotification(t`Email settings have been updated`);
    },
    onError: () => {
      showErrorNotification(
        t`Failed to update email settings`,
        t`Something went wrong. Please try again later.`
      );
    },
  });

  const submitForm = async (values: EmailSettingsFormData) => {
    updateTenantBrandConfiguration.mutate(values);
  };

  return (
    <form className="flex flex-col gap-5" onSubmit={handleSubmit(submitForm)}>
      <div className="px-4 py-5 space-y-6 sm:px-6">
        <div className="flex flex-col w-full gap-6 md:w-1/2">
          <div className="space-y-4 sm:col-span-3">
            <Label text={t`Support Email`}>
              <InputText
                type="email"
                {...register('supportEmail', {
                  required: true,
                })}
                hasError={Boolean(errors.supportEmail)}
                placeholder={t`example@mail.com`}
              />
              <ValidationError isVisible={Boolean(errors.supportEmail)}>
                {errors.supportEmail?.message}
              </ValidationError>
            </Label>
            <Label text={t`From Email`}>
              <InputText
                type="email"
                {...register('fromEmail', {
                  required: true,
                })}
                hasError={Boolean(errors.fromEmail)}
                placeholder={t`example@mail.com`}
              />
              <ValidationError isVisible={Boolean(errors.fromEmail)}>
                {errors.fromEmail?.message}
              </ValidationError>
            </Label>
          </div>
        </div>
        <footer className="flex justify-end mt-8">
          <Button
            size="lg"
            type="submit"
            className="w-fit"
            disabled={updateTenantBrandConfiguration.isLoading}
            isLoading={updateTenantBrandConfiguration.isLoading}
          >
            <Trans>Save</Trans>
          </Button>
        </footer>
      </div>
    </form>
  );
};
