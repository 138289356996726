import {I18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
  MerchantAccountsListOutput,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {TableColumnSize, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  HighlightedText,
  TruncatedText,
  getLocalizedStatusMap,
  getTableMeta,
  merchantAccountStatusToColor,
} from '@zentact/ui-tailwind';
import {MerchantActions} from './merchant-actions';
import {MerchantReviewPanelProps} from './merchant-review-panel';

const columnsHelper = createColumnHelper<MerchantAccountsListOutput['rows'][0]>();

type Props = {
  refetchMerchantList: () => void;
  mode?: 'onlyPending';
  hideMerchantSearch?: boolean;
  hideAllSorting?: boolean;
  openDetailsPanel?: (row: MerchantAccountsListItem) => void;
  openReviewPanel?: (row: MerchantReviewPanelProps['merchantAccountRow']) => void;
  openUpdateSettlementDelayDays?: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  i18n: I18n;
};
export const getMerchantListColumns = ({
  refetchMerchantList,
  hideMerchantSearch,
  hideAllSorting,
  mode,
  openDetailsPanel,
  openReviewPanel,
  openUpdateSettlementDelayDays,
  i18n,
}: Props) => {
  const organizationNameColumn = columnsHelper.display({
    cell: props => <TruncatedText text={props.row.original.organization.name} />,
    header: () => <Trans>Organization</Trans>,
    id: 'organizationName',
    meta: {
      sort: !hideAllSorting
        ? {
            isSortable: true,
          }
        : undefined,
    },
    size: TableColumnSize.XL,
  });
  const merchantAccountColumn = columnsHelper.display({
    cell: props => {
      const {filterValues} = getTableMeta(props.table);
      const searchString = filterValues?.businessName as string;
      const textContent = props.row.original.merchantAccount.businessName || i18n._('N/A');

      if (hideMerchantSearch) {
        return textContent;
      }

      return (
        <HighlightedText
          text={textContent}
          highlight={searchString}
          className="break-all text-wrap"
        />
      );
    },
    id: 'businessName',
    header: () => <Trans>Merchant Account</Trans>,
    meta: {
      collapseAt: 'md',
    },
  });
  const actionsColumn = columnsHelper.display({
    id: 'actions',
    cell: props => (
      <MerchantActions
        merchantAccountRow={props.row.original}
        registrationSessionId={props.row.original.sessionId}
        userId={props.row.original.userId}
        inviteeEmail={props.row.original.inviteeEmail || ''}
        businessName={props.row.original.merchantAccount.businessName || ''}
        refetchOrganizationList={refetchMerchantList}
        openDetailsPanel={openDetailsPanel ?? null}
        openReviewPanel={openReviewPanel}
        openUpdateSettlementDelayDays={openUpdateSettlementDelayDays}
        viewType="dots-button"
      />
    ),
    size: TableColumnSize.ACTIONS,
  });
  if (mode === 'onlyPending') {
    return [
      organizationNameColumn,
      merchantAccountColumn,
      columnsHelper.display({
        id: 'signatory',
        cell: props => `${props.row.original.firstName ?? ''} ${props.row.original.lastName ?? ''}`,
        header: () => <Trans>Signatory</Trans>,
        meta: {
          sort: !hideAllSorting
            ? {
                isSortable: true,
              }
            : undefined,
          collapseAt: 'lg',
        },
      }),
      columnsHelper.accessor('kycCompletedAt', {
        cell: info => {
          const value = info.getValue();
          return value ? formatLocaleDate(value, 'shortWithTime') : '';
        },
        header: () => <Trans>KYC Completed At</Trans>,
        meta: {
          sort: !hideAllSorting
            ? {
                isSortable: true,
              }
            : undefined,
        },
        size: TableColumnSize.L,
      }),
      actionsColumn,
    ];
  }
  return [
    organizationNameColumn,
    merchantAccountColumn,
    columnsHelper.accessor(row => row.inviteeEmail, {
      id: 'contact',
      cell: info => <TruncatedText text={info.getValue() || i18n._('N/A')} maxLength={25} />,
      header: () => <Trans>Contact</Trans>,
      meta: {
        collapseAt: 'lg',
        visibleAt: 'md',
      },
    }),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={merchantAccountStatusToColor[info.getValue()] || 'blue'}
          label={
            getLocalizedStatusMap(i18n, info.row.original.sessionExpiresAt)[info.getValue()] ||
            info.getValue()
          }
        />
      ),
      meta: {
        collapseAt: 'sm',
      },
      header: () => <Trans>Status</Trans>,
      size: TableColumnSize.M,
    }),
    columnsHelper.accessor('updatedAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Last Active</Trans>,
      meta: {
        sort: !hideAllSorting
          ? {
              isSortable: true,
            }
          : undefined,
      },
      size: TableColumnSize.S,
    }),
    actionsColumn,
  ];
};
