import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ChargebackListItemOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {
  Breadcrumbs,
  Button,
  ChargebackDetailsPanel,
  EntityPicker,
  MerchantAccountsPicker,
  ResetTableFiltersButton,
  TableSortValue,
  Typography,
  displayChargebackTypeMap,
  fetchFullListFromPaginatedEndpoint,
  useNotification,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {ChargebackList} from '@zentact/ui-tailwind/layout/payments/chargeback-list/chargeback-list';
import {DateTime} from 'luxon';
import {useCallback, useEffect, useState} from 'react';
import {z} from 'zod';
import {exportToCsvDisputes} from './csv-export';

const getBreadCrumbs = () => [
  {name: t`Transactions`, href: RoutePath.TRANSACTIONS, current: false},
  {name: t`Disputes`, href: '#', current: true},
];

export const Disputes = () => {
  const {i18n} = useLingui();
  const {
    orgsWithBoardedMerchants: organizationList,
    activeAndInactiveMerchantAccounts: merchantAccounts,
    pspMerchantAccountName,
  } = useStore();
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const disputesSchema = z.object({
    type: z
      .array(z.string())
      .or(z.string().transform(str => str.split(',')))
      .optional(),
    status: z
      .array(z.string())
      .or(z.string().transform(str => str.split(',')))
      .optional(),
    startDate: z
      .date()
      .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
      .nullable()
      .optional(),
    endDate: z
      .date()
      .or(
        z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
      )
      .nullable()
      .optional(),
    pspReferenceId: z.string().optional(),
    paymentPspReferenceId: z.string().optional(),
    paymentMethod: z
      .array(z.string())
      .or(z.string().transform(str => str.split(',')))
      .optional(),
    shopperEmail: z.string().email().optional(),
    selectedOrganization: z.string().optional(),
    merchantAccountId: z.string().optional(),
  });

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(disputesSchema);

  const type = typedSearchParams?.type;
  const status = typedSearchParams?.status;
  const pspReferenceId = typedSearchParams?.pspReferenceId;
  const paymentPspReferenceId = typedSearchParams?.paymentPspReferenceId;
  const paymentMethod = typedSearchParams?.paymentMethod;
  const shopperEmail = typedSearchParams?.shopperEmail;
  const selectedOrganization = typedSearchParams?.selectedOrganization;
  const selectedMerchantId = typedSearchParams?.merchantAccountId;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'createdAt', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});

  const {
    data: {filtersDescription: paymentMethodsFiltersDescription},
  } = trpc.payment.paymentMethods.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    initialData: {list: [], filters: [], filtersDescription: {}},
  });

  const chargebackFilters = {
    ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    where: {
      ...(type?.length && {type}),
      ...(status?.length && {status}),
      ...(startDate && {startDate: startDate.toISOString()}),
      ...(endDate && {endDate: endDate.toISOString()}),
      ...(pspReferenceId && {pspReferenceId}),
      ...(paymentPspReferenceId && {paymentPspReferenceId}),
      ...(paymentMethod?.length && {
        paymentMethod: paymentMethod.flatMap(
          // biome-ignore lint/style/noNonNullAssertion: TODO
          filter => paymentMethodsFiltersDescription[filter]!
        ),
      }),
      ...(shopperEmail && {shopperEmail}),
      ...(selectedOrganization && {organizationIds: [selectedOrganization]}),
      ...(selectedMerchantId && {merchantAccountId: selectedMerchantId}),
      ...(pspMerchantAccountName && {pspMerchantAccountName}),
    },
  };
  const chargebackList = trpc.chargeback.chargebackList.useQuery(
    {
      ...chargebackFilters,
      ...pagination,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
  const trpcContext = trpc.useUtils();

  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [chargebackDetailsRow, setChargebackDetailsRow] = useState<ChargebackListItemOutput | null>(
    null
  );

  const handleOpenSidePanel = useCallback(
    (row: ChargebackListItemOutput) => {
      setChargebackDetailsRow(row);
      openSidePanel();
    },
    [openSidePanel, setChargebackDetailsRow]
  );

  const handleOrganizationSelect = useCallback(
    (selectedOrganization?: string) => {
      setTypedSearchParams({selectedOrganization});
    },
    [setTypedSearchParams]
  );

  const handleDisputeTypeSelect = useCallback(
    (selectedType?: string) => {
      setTypedSearchParams(selectedType ? {type: [selectedType]} : {type: []});
    },
    [setTypedSearchParams]
  );

  const handleMerchantChange = useCallback(
    (merchantAccountId?: string) => {
      setTypedSearchParams({merchantAccountId});
    },
    [setTypedSearchParams]
  );

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullDisputeList = await fetchFullListFromPaginatedEndpoint(
        trpcContext.chargeback.chargebackList,
        chargebackFilters
      );
      exportToCsvDisputes(fullDisputeList.rows, i18n);
      showSuccessNotification(t`Disputes .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Disputes .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, chargebackFilters, trpcContext, exportToCsvDisputes]);

  // When filter or sorting changes we need to reset pagination
  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  useEffect(() => {
    const isOrganizationMerchant = merchantAccounts
      ?.filter(merchant => merchant.organizationId === selectedOrganization)
      .some(merchant => merchant.id === selectedMerchantId);
    if (selectedOrganization && !isOrganizationMerchant) {
      setTypedSearchParams({merchantAccountId: undefined});
    }
  }, [setTypedSearchParams, selectedOrganization, merchantAccounts, selectedMerchantId]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-2xl:flex-wrap 2xl:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Disputes</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-2xl:flex-wrap 2xl:items-center">
          <div className="max-sm:w-full">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <div className="font-normal shrink-0 max-md:w-full">
            <EntityPicker
              selected={type?.[0]}
              onChange={handleDisputeTypeSelect}
              options={Object.entries(displayChargebackTypeMap).map(([id, name]) => ({
                id: id as keyof typeof displayChargebackTypeMap,
                name,
              }))}
              label={t`All Dispute Types`}
            />
          </div>
          <div className="font-normal shrink-0 max-md:w-full">
            <EntityPicker
              selected={selectedOrganization}
              onChange={handleOrganizationSelect}
              options={organizationList}
            />
          </div>
          {!!merchantAccounts?.length && (
            <div className="font-normal shrink-0 w-60">
              <MerchantAccountsPicker
                selectedMerchantAccount={selectedMerchantId || undefined}
                onSelectMerchantAccount={handleMerchantChange}
                merchantAccountsOptions={
                  selectedOrganization
                    ? merchantAccounts.filter(
                        merchantAccount => merchantAccount.organizationId === selectedOrganization
                      )
                    : merchantAccounts
                }
                allLabel={t`All Merchant Accounts`}
              />
            </div>
          )}
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!chargebackList.data || chargebackList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto">
        <ChargebackList
          chargebackList={chargebackList.data}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleOpenSidePanel}
          showOrganizationColumn
          isLoading={
            chargebackList.isLoading ||
            (chargebackList.isRefetching && chargebackList.isPreviousData)
          }
        />
      </div>
      <ChargebackDetailsPanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        chargebackRow={chargebackDetailsRow}
        showProfitAndProcessingFee
        trpc={trpc}
      />
    </div>
  );
};
