import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useSplitConfigurationGroups} from '@/hooks';
import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {ErrorCode, MerchantAccountPublicStatus, isFormattedTrpcError} from '@zentact/common';
import {
  AlertOverlayWithConfirmation,
  Button,
  DropDownMinimalMenuIcon,
  InputToggle,
  SlideOverWithBrandedHeader,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {AssignOrganizationDialog} from '../assign-organization-dialog';
import {
  OrganizationForm,
  OrganizationFormData,
  getOrganizationFormSchema,
} from '../organization-form/organization-form';
import {MerchantReviewPanelProps} from './merchant-review-panel';

type StableProps = {
  businessName: string;
  registrationSessionId: string;
  refetchOrganizationList: () => void;
  openReviewPanel?: (row: MerchantReviewPanelProps['merchantAccountRow']) => void;
  openUpdateSettlementDelayDays?: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  openAssignMerchantFeeGroupPanel?: (
    row: MerchantAccountDetailsOutput | MerchantAccountsListItem | null
  ) => void;
  viewType: 'dots-button' | 'chevron-button';
  userId: string | null;
  inviteeEmail: string;
};

type Props = StableProps &
  (
    | {
        merchantAccountRow: MerchantAccountsListItem;
        openDetailsPanel: ((row: MerchantAccountsListItem) => void) | null;
      }
    | {
        merchantAccountRow: MerchantAccountDetailsOutput;
      }
  );

const statusToItemsMap = (
  action: (
    action:
      | 'deactivate'
      | 'resend'
      | 'revoke'
      | 'editAndResend'
      | 'reactivate'
      | 'review'
      | 'details'
      | 'cancelOnboarding'
      | 'archive'
      | 'updateSettlementDelay'
      | 'assignFeeGroup'
      | 'openOnboarding'
      | 'assignOrganization'
  ) => void,
  {
    includeViewDetails,
    includeReassignOption,
    includeReviewOption,
    managedRiskEnabled,
  }: {
    includeViewDetails?: boolean;
    includeReassignOption: boolean;
    includeReviewOption?: boolean;
    managedRiskEnabled?: boolean;
  }
): Record<
  MerchantAccountPublicStatus,
  {name: string; onClick: () => void; itemClassName?: string}[]
> => {
  const details = includeViewDetails ? [{name: t`Details`, onClick: () => action('details')}] : [];
  const assignFeeGroup = includeReassignOption
    ? [{name: t`Assign Fee Group`, onClick: () => action('assignFeeGroup')}]
    : [];
  return {
    [MerchantAccountPublicStatus.ACTIVE]: [
      ...details,
      ...assignFeeGroup,
      ...(!managedRiskEnabled
        ? [{name: t`Update Settlement Delay`, onClick: () => action('updateSettlementDelay')}]
        : []),
      {
        name: t`Deactivate`,
        onClick: () => action('deactivate'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.REJECTED]: [
      ...details,
      {name: t`Resend`, onClick: () => action('resend')},
      {name: t`Open Hosted Onboarding`, onClick: () => action('openOnboarding')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.AWAITING_INFO]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Resend`, onClick: () => action('resend')},
      {name: t`Open Hosted Onboarding`, onClick: () => action('openOnboarding')},
      {
        name: t`Cancel Onboarding`,
        onClick: () => action('cancelOnboarding'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.ONBOARDING]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Resend`, onClick: () => action('resend')},
      {
        name: t`Cancel Onboarding`,
        onClick: () => action('cancelOnboarding'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.DEACTIVATED]: [
      ...details,
      {name: t`Reactivate`, onClick: () => action('reactivate')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.INVITED]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Edit & Resend`, onClick: () => action('editAndResend')},
      {
        name: t`Revoke Invitation`,
        onClick: () => action('revoke'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.INITIATED]: [
      ...details,
      ...assignFeeGroup,
      {name: t`Edit & Invite`, onClick: () => action('editAndResend')},
      {
        name: t`Revoke`,
        onClick: () => action('revoke'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.INVITE_EXPIRED]: [
      ...details,
      {name: t`Edit & Resend`, onClick: () => action('editAndResend')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.INVITE_REVOKED]: [
      ...details,
      {name: t`Edit & Resend`, onClick: () => action('editAndResend')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.ONBOARDING_CANCELLED]: [
      ...details,
      {name: t`Resend Invitation`, onClick: () => action('resend')},
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.AWAITING_REVIEW]: [
      ...details,
      ...assignFeeGroup,
      ...(includeReviewOption ? [{name: t`Review`, onClick: () => action('review')}] : []),
    ],
    [MerchantAccountPublicStatus.REVIEW_REJECTED]: [
      ...details,
      ...(includeReviewOption ? [{name: t`Review again`, onClick: () => action('review')}] : []),
      {name: t`Archive`, onClick: () => action('archive'), itemClassName: 'text-red-500'},
    ],
    [MerchantAccountPublicStatus.NEEDS_ORGANIZATION]: [
      ...details,
      {
        name: t`Assign Organization`,
        onClick: () => action('assignOrganization'),
        itemClassName: 'text-red-500',
      },
    ],
    [MerchantAccountPublicStatus.ASSIGNING_ORGANIZATION]: details,
  };
};

export const MerchantActions = (props: Props) => {
  const {
    merchantAccountRow,
    registrationSessionId,
    userId,
    inviteeEmail,
    businessName,
    refetchOrganizationList,
    openReviewPanel,
    viewType,
  } = props;
  const {user} = useAuth0();
  const {tenant, pspMerchantAccountName} = useStore();
  const [resendInviteSlideOutOpen, setResendInviteSlideOutOpen] = useState(false);
  const [deactivateConfirmationOpen, setDeactivateConfirmationOpen] = useState(false);
  const [cancelOnboardingConfirmationOpen, setCancelOnboardingConfirmationOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [archiveConfirmationOpen, setArchiveConfirmationOpen] = useState(false);
  const [assignOrganizationOpen, setAssignOrganization] = useState(false);

  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {splitConfigurationGroups} = useSplitConfigurationGroups();
  const navigate = useNavigate();

  const userMerchantsList = trpc.merchantAccount.getMerchantAccountsList.useQuery(
    {
      where: {
        ...(userId && {
          userId,
        }),
        pspMerchantAccountName,
      },
    },
    {
      enabled: cancelOnboardingConfirmationOpen,
    }
  );
  const filteredUserMerchantsList = userMerchantsList.data?.rows.filter(
    merchant =>
      merchant.status === MerchantAccountPublicStatus.ONBOARDING ||
      merchant.status === MerchantAccountPublicStatus.INVITED ||
      merchant.status === MerchantAccountPublicStatus.INITIATED ||
      merchant.status === MerchantAccountPublicStatus.ACTIVE
  );

  const showUserDeleteToggle =
    !!filteredUserMerchantsList && filteredUserMerchantsList.length === 1;

  const resendForm = useForm<OrganizationFormData>({
    resolver: zodResolver(getOrganizationFormSchema(tenant.features?.merchantReferenceIdRequired)),
  });
  const {handleSubmit, setError} = resendForm;

  const merchantAccountId =
    'merchantAccount' in merchantAccountRow
      ? merchantAccountRow.merchantAccount?.id
      : merchantAccountRow.id;

  const getInvitationDetail = trpc.merchantRegistration.getInvitationDetails.useQuery(
    {
      registrationSessionId,
    },
    {enabled: resendInviteSlideOutOpen}
  );
  const revokeMutation = trpc.merchantRegistration.revokeInvitation.useMutation();
  const deleteOrganizationUserAccount = trpc.users.deleteOrganizationUserAccount.useMutation({
    onSuccess: () => {
      refetchOrganizationList();
      showSuccessNotification(t`User deleted`, t`You have successfully deleted user`);
    },
  });
  const reactivateMutation = trpc.merchantAccount.reactivateMerchantAccount.useMutation({
    onSuccess: () => {
      refetchOrganizationList();
      showSuccessNotification(
        t`Organization Re-activated`,
        t`You have successfully reactivated ${businessName}.`
      );
    },
  });
  const deactivateMutation = trpc.merchantAccount.deactivateMerchantAccount.useMutation({
    onSuccess: () => {
      refetchOrganizationList();
      setDeactivateConfirmationOpen(false);
      showSuccessNotification(
        t`Merchant account deactivated`,
        t`You have successfully deactivated ${businessName}.`
      );
    },
  });

  const editAndResendMutation = trpc.merchantRegistration.editAndResendInvitation.useMutation({
    onSuccess: (_res, {businessName}) => {
      setResendInviteSlideOutOpen(false);
      refetchOrganizationList();
      showSuccessNotification(
        t`Invitation resent!`,
        t`You have successfully resent the invitation to ${businessName}.`
      );
    },
    onError: error => {
      const errorCode = isFormattedTrpcError(error)
        ? error.data.errorCode
        : ErrorCode.ERROR_GENERIC;
      if (errorCode === ErrorCode.MERCHANT_REFERENCE_ID_ALREADY_EXISTS) {
        setError('merchantReferenceId', {
          type: 'manual',
          message: t`Merchant Reference ID already exists`,
        });

        return;
      }
      if (errorCode === ErrorCode.MERCHANT_REGISTRATION_ALREADY_EXISTS) {
        setError('merchantName', {
          type: 'manual',
          message: t`Merchant with the same name already exists`,
        });

        return;
      }
      setResendInviteSlideOutOpen(false);
      showErrorNotification(t`Error`, error.message);
    },
  });

  const resendMutation = trpc.merchantRegistration.resendInvitation.useMutation({
    onSuccess: () => {
      setResendInviteSlideOutOpen(false);
      refetchOrganizationList();
      showSuccessNotification(
        t`Invitation sent!`,
        t`You have successfully sent the invitation to ${businessName}.`
      );
    },
    onError: error => {
      setResendInviteSlideOutOpen(false);
      showErrorNotification(t`Error`, error.message);
    },
  });

  const archiveMutation = trpc.merchantAccount.archiveMerchantAccount.useMutation({
    onSuccess: () => {
      refetchOrganizationList();
      setArchiveConfirmationOpen(false);
      navigate(RoutePath.CUSTOMERS_MERCHANTS);
      showSuccessNotification(
        t`Merchant archived`,
        t`You have successfully archived ${businessName}.`
      );
    },
  });

  const trpcContext = trpc.useUtils();

  const handleOpenHop = useCallback(async () => {
    try {
      const {redirectUrl} = await trpcContext.merchantRegistration.getResumeHopLink.fetch({
        registrationSessionId,
      });
      window.location.href = redirectUrl;
    } catch (e) {
      showErrorNotification(t`Failed to generate a hop link`, (e as Error).message);
    }
  }, [registrationSessionId, showErrorNotification]);

  const handleAction = (action: string) => {
    if (action === 'details' && 'openDetailsPanel' in props && props.openDetailsPanel) {
      props.openDetailsPanel(props.merchantAccountRow);
    }
    if (action === 'editAndResend') {
      setResendInviteSlideOutOpen(true);
    }
    if (action === 'revoke') {
      revokeMutation.mutate(
        {registrationSessionId},
        {
          onSuccess: () => {
            refetchOrganizationList();
            showSuccessNotification(
              t`Invitation revoked!`,
              t`You have successfully revoked the invitation.`
            );
          },
        }
      );
    }
    if (action === 'cancelOnboarding') {
      setCancelOnboardingConfirmationOpen(true);
    }
    if (action === 'reactivate') {
      reactivateMutation.mutate({registrationSessionId});
    }
    if (action === 'deactivate') {
      setDeactivateConfirmationOpen(true);
    }
    if (action === 'resend') {
      resendMutation.mutate({
        registrationSessionId,
        inviterName: user?.name || user?.email || 'Unknown',
      });
    }
    if (action === 'review' && openReviewPanel) {
      openReviewPanel(merchantAccountRow);
    }
    if (action === 'archive') {
      setArchiveConfirmationOpen(true);
    }
    if (action === 'updateSettlementDelay' && props.openUpdateSettlementDelayDays) {
      props.openUpdateSettlementDelayDays(merchantAccountRow);
    }
    if (action === 'assignFeeGroup' && props.openAssignMerchantFeeGroupPanel) {
      props.openAssignMerchantFeeGroupPanel(merchantAccountRow);
    }
    if (action === 'openOnboarding') {
      handleOpenHop();
    }
    if (action === 'assignOrganization') {
      setAssignOrganization(true);
    }
  };

  useEffect(() => {
    if (!getInvitationDetail.data) {
      return;
    }

    resendForm.reset({
      organization: {
        organizationType: 'new',
        organizationName: getInvitationDetail.data.organizationName,
      },
      merchantName: getInvitationDetail.data.merchantName ?? undefined,
      merchantEmail: getInvitationDetail.data.merchantEmail,
      merchantReferenceId: getInvitationDetail.data.merchantReferenceId ?? undefined,
      splitConfigurationGroupId: getInvitationDetail.data.splitConfigurationGroupId ?? undefined,
    });
  }, [getInvitationDetail.data]);

  const handleDeactivate = useCallback(() => {
    deactivateMutation.mutate({registrationSessionId});
  }, [registrationSessionId]);

  const handleCancelOnboarding = () => {
    if (userId && deleteUser) {
      deleteOrganizationUserAccount.mutate({userId});
    }
    revokeMutation.mutate(
      {registrationSessionId},
      {
        onSuccess: () => {
          refetchOrganizationList();
          setCancelOnboardingConfirmationOpen(false);
          showSuccessNotification(
            t`Onboarding canceled!`,
            t`You have successfully canceled the onboarding.`
          );
        },
      }
    );
  };

  const handleEditAndResend = useCallback(
    (data: OrganizationFormData) => {
      editAndResendMutation.mutate({
        inviterName: user?.name || user?.email || 'Unknown',
        registrationSessionId,
        businessName: data.merchantName,
        email: data.merchantEmail,
        merchantReferenceId: data.merchantReferenceId,
        splitConfigurationGroupId: data.splitConfigurationGroupId,
      });
    },
    [user, registrationSessionId]
  );

  const handleArchived = useCallback(() => {
    if (merchantAccountId) {
      archiveMutation.mutate({merchantAccountId});
    }
  }, [merchantAccountId]);

  const menuItems = statusToItemsMap(handleAction, {
    includeViewDetails: 'openDetailsPanel' in props && !!props.openDetailsPanel,
    includeReassignOption: !!props.openAssignMerchantFeeGroupPanel,
    includeReviewOption: !!props.openReviewPanel,
    managedRiskEnabled: merchantAccountRow.managedRiskEnabled,
  })[merchantAccountRow.status];
  if (!menuItems) return null;

  return (
    <>
      <DropDownMinimalMenuIcon
        items={menuItems}
        buttonContent={viewType === 'chevron-button' ? <Trans>Actions</Trans> : undefined}
      />
      {deactivateConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={deactivateConfirmationOpen}
          setOpen={setDeactivateConfirmationOpen}
          handleAction={handleDeactivate}
          localeText={{
            title: t`Deactivate merchant`,
            description: t`Are you sure you want to deactivate ${businessName}?`,
            confirm: t`Deactivate`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {cancelOnboardingConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={cancelOnboardingConfirmationOpen}
          setOpen={setCancelOnboardingConfirmationOpen}
          handleAction={handleCancelOnboarding}
          localeText={{
            title: t`Cancel onboarding`,
            description: t`Are you sure you would like to cancel merchant onboarding for ${businessName}?`,
            confirm: t`Cancel Onboarding`,
            cancel: t`Cancel`,
          }}
        >
          {showUserDeleteToggle ? (
            <div className="items-center justify-center px-6 mb-3 sm:flex">
              <InputToggle
                toggleSize="md"
                onClick={() => setDeleteUser(prev => !prev)}
                defaultChecked={false}
                // biome-ignore lint/complexity/noUselessFragments: <explanation>
                label={<></>}
              />
              <p className="text-sm text-gray-500">
                <Trans>
                  Do you also want to delete the user {inviteeEmail} from the{' '}
                  {merchantAccountRow.organization.name} organization?
                </Trans>
              </p>
            </div>
          ) : undefined}
        </AlertOverlayWithConfirmation>
      )}
      {archiveConfirmationOpen && (
        <AlertOverlayWithConfirmation
          open={archiveConfirmationOpen}
          setOpen={setArchiveConfirmationOpen}
          handleAction={handleArchived}
          localeText={{
            title: t`Archive merchant`,
            description: t`Are you sure you want to archive ${businessName}? This action cannot be undone. You will still be able to create a new merchant account with the same business name.`,
            confirm: t`Archive`,
            cancel: t`Cancel`,
          }}
        />
      )}
      {assignOrganizationOpen && (
        <AssignOrganizationDialog
          isOpen={assignOrganizationOpen}
          setOpen={setAssignOrganization}
          merchantAccountId={merchantAccountId}
          businessName={businessName}
          triggerRefetch={refetchOrganizationList}
        />
      )}
      <SlideOverWithBrandedHeader
        isOpen={resendInviteSlideOutOpen && Boolean(getInvitationDetail.isFetched)}
        title={
          merchantAccountRow.status === MerchantAccountPublicStatus.INITIATED
            ? t`Edit and Invite`
            : t`Edit and Resend Invitation`
        }
        text={
          merchantAccountRow.status === MerchantAccountPublicStatus.INITIATED
            ? t`Edit the invitation and send it to the organization.`
            : t`Edit the invitation and resend it to the organization.`
        }
        closeHandler={() => {
          setResendInviteSlideOutOpen(false);
        }}
        footer={
          <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
            <div className="flex shrink-0 gap-x-3">
              <Button
                variant="primary"
                size="lg"
                className="w-fit"
                onClick={handleSubmit(handleEditAndResend)}
                isLoading={editAndResendMutation.isLoading}
              >
                <Trans>Send Invite</Trans>
              </Button>
            </div>
            <Button
              variant="secondary"
              size="lg"
              className="w-fit"
              onClick={() => setResendInviteSlideOutOpen(false)}
            >
              <Trans>Close</Trans>
            </Button>
          </footer>
        }
      >
        <form onSubmit={handleSubmit(handleEditAndResend)}>
          <OrganizationForm
            form={resendForm}
            organizationReadOnly
            splitConfigurationGroups={splitConfigurationGroups ?? []}
            merchantReferenceIdRequired={tenant.features?.merchantReferenceIdRequired}
          />
        </form>
      </SlideOverWithBrandedHeader>
    </>
  );
};
