import {I18n} from '@lingui/core';
import {t} from '@lingui/macro';
import {ChargebackListOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {
  DeclinedPaymentsListOutput,
  PaymentsListOutput,
} from '@zentact/api/src/trpc/routers/paymentRouter';
import {
  CSV_EXPORT_FULL_DATE_FORMAT,
  CurrencyCode,
  LocaleCode,
  capitalizeCustomAttribute,
  formatAmountWithoutCurrency,
  getPaymentMethodName,
  paymentMethodVariantTranslationMap,
} from '@zentact/common';
import {
  ExportToCsv,
  displayChargebackTypeMap,
  displayPaymentStatusMap,
  getChargebackDisputeStatusMap,
} from '@zentact/ui-tailwind';
import {getRefusalReasonTranslation} from '@zentact/ui-tailwind/layout/payments/details-panel/declined-payment-details-panel';
import {DateTime} from 'luxon';

export const exportToCsvPayments = (
  fullPaymentList: PaymentsListOutput['rows'],
  i18n: I18n,
  customAttributeNames?: string[]
) => {
  const exporter = new ExportToCsv({
    filename: `payments-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullPaymentList.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullPaymentList.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Transaction Id`]: row.pspReferenceId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Reference Id`]: row.reference,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Store ID`]: row.storeId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Store Reference ID`]: row.store?.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Auth Code`]: row.authCode || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Account`]: row.merchantAccount.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Organization`]: row.organization.name,
      ...(customAttributeNames
        ? Object.fromEntries(
            customAttributeNames.map(attrName => [
              t`${capitalizeCustomAttribute(attrName)}`,
              row.paymentAttributes.find(paymentAttribute => paymentAttribute.name === attrName)
                ?.value || t`N/A`,
            ])
          )
        : {}),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Authorized Amount`]: formatAmountWithoutCurrency(
        row.authorizedAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Surcharge Amount`]: formatAmountWithoutCurrency(
        row.surchargeAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Refunded Amount`]: formatAmountWithoutCurrency(
        row.refundedAmount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Fee`]: row.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.authorizedAmount - row.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Amount`]: row.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method`]: row.paymentMethod,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method Variant`]: row.paymentMethodVariant,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Card ending`]: row.cardSummary ? `*${row.cardSummary}` : '',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Status`]: displayPaymentStatusMap[row.status],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Updated At`]: DateTime.fromISO(row.updatedAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Created At`]: DateTime.fromISO(row.createdAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Source`]: row.source,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
    }))
  );
};

export const exportToCsvDeclinedPayments = (
  fullPaymentList: DeclinedPaymentsListOutput,
  i18n: I18n,
  customAttributesNames?: string[]
) => {
  const exporter = new ExportToCsv({
    filename: `declined-payments-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!fullPaymentList.rows.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    fullPaymentList.rows.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Transaction Id`]: row.pspReferenceId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Transaction Id`]: row.reference,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Account Reference Id`]: row.merchantAccount.tenantMerchantReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Store ID`]: row.storeId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Store Reference ID`]: row.store?.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Account`]: row.merchantAccount.businessName,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Organization`]: row.organization.name,
      ...(customAttributesNames
        ? Object.fromEntries(
            customAttributesNames.map(attrName => [
              t`${capitalizeCustomAttribute(attrName)}`,
              row.paymentAttributes.find(paymentAttribute => paymentAttribute.name === attrName)
                ?.value || t`N/A`,
            ])
          )
        : {}),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Amount`]: formatAmountWithoutCurrency(
        row.amount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method`]: getPaymentMethodName(row.paymentMethod, i18n.locale as LocaleCode),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method Variant`]:
        paymentMethodVariantTranslationMap.get(row.paymentMethodVariant) ??
        row.paymentMethodVariant,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Card ending`]: row.cardSummary ? `*${row.cardSummary}` : '',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Refusal Reason`]: row.refusalReason,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Refusal Reason Description`]: getRefusalReasonTranslation(i18n, row.refusalReason),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Updated At`]: DateTime.fromISO(row.updatedAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Created At`]: DateTime.fromISO(row.createdAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Source`]: row.source,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
    }))
  );
};

export const exportToCsvDisputes = (chargebackList: ChargebackListOutput['rows'], i18n: I18n) => {
  const exporter = new ExportToCsv({
    filename: `disputes-${+new Date()}`,
    showLabels: true,
    useKeysAsHeaders: true,
  });

  if (!chargebackList.length) {
    throw new Error(t`No data to export`);
  }

  exporter.generateCsv(
    chargebackList.map(row => ({
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Dispute Transaction Id`]: row.pspReferenceId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Transaction Id`]: row.payment.pspReferenceId,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Merchant Reference Id`]: row.payment.reference,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Store Id`]: row.storeId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Store Reference Id`]: row.store?.storeReferenceId || t`N/A`,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Currency`]: row.currency,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Amount`]: formatAmountWithoutCurrency(
        row.amount,
        i18n.locale as LocaleCode,
        row.currency as CurrencyCode
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Type`]: displayChargebackTypeMap[row.type],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Status`]: getChargebackDisputeStatusMap(i18n)[row.disputeStatus],
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Reason`]: row.reason,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Reason Code`]: row.reasonCode,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Fee`]: row.payment.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.payment.authorizedAmount - row.payment.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Merchant Amount`]: row.payment.merchantSplitAmount
        ? formatAmountWithoutCurrency(
            row.payment.merchantSplitAmount,
            i18n.locale as LocaleCode,
            row.currency as CurrencyCode
          )
        : 'pending',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method`]: row.paymentMethod,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Method Variant`]: row.payment.paymentMethodVariant,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Source`]: row.payment.source,
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Card ending`]: row.payment.cardSummary ? `*${row.payment.cardSummary}` : '',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Created At`]: DateTime.fromISO(row.createdAt).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Updated At`]: DateTime.fromISO(row.updatedAt).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Dispute Defense Ends At`]: row.defensePeriodEndsAt
        ? DateTime.fromISO(row.defensePeriodEndsAt).toFormat(CSV_EXPORT_FULL_DATE_FORMAT)
        : '-',
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`Payment Created At`]: DateTime.fromISO(row.payment.createdAt).toFormat(
        CSV_EXPORT_FULL_DATE_FORMAT
      ),
      // biome-ignore lint/complexity/useLiteralKeys: Translated keys are used for CSV headers
      [t`PSP Merchant Account`]: row.pspMerchantAccountName,
    }))
  );
};
