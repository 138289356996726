import {cn} from '../../utils';

const specialSymbolsRegexp = /[/\-\\^$*+?.()|[\]{}]/g;

export const HighlightedText = ({
  text = '',
  highlight = '',
  // biome-ignore lint/style/useNamingConvention: Uppercase component
  as: Tag = 'span',
  className,
}: {
  text?: string;
  highlight?: string;
  className?: string;
  as?: 'span' | 'div' | 'label' | 'p';
}) =>
  highlight?.trim() ? (
    <>
      {text
        .split(new RegExp(`(${highlight.replace(specialSymbolsRegexp, '\\$&')})`, 'gi'))
        .map((part, i) => (
          <mark
            key={`text-part-${i}`}
            className={cn(
              'bg-transparent',
              part.toLowerCase() === highlight.toLowerCase() && 'bg-yellow-400',
              className
            )}
          >
            {part}
          </mark>
        ))}
    </>
  ) : (
    <Tag className={cn(className)}>{text}</Tag>
  );
